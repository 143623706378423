import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {AssistantResponse} from '#/repositories/assistants-api/requests/fetch-assistant.ts';
import {LinkRequest} from '#/repositories/assistants-api/requests/link.ts';
import {ModelVisibilityRequest} from '#/repositories/assistants-api/requests/visibility.ts';
import {AxiosResponse} from 'axios';

export type UpdateAssistantRequest = {
  name: string;
  description: string;
  instructions: string;
  links: LinkRequest[];
  prompt_starters: string[];
  visibility: ModelVisibilityRequest;
  avatar_url?: string;
  use_system_prompt: boolean;
  allowed_functions?: string[];
  allowed_external_services?: string[];
};

export const updateAssistant = (
  id: string,
  request: UpdateAssistantRequest,
): Promise<AxiosResponse<AssistantResponse>> => {
  return ScoutAPI.put<UpdateAssistantRequest, AxiosResponse<AssistantResponse>>(`/assistants/${id}`, request);
};
