import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import {useMemo} from 'react';

export type ConversationsGroupDateKey = 'today' | 'yesterday' | 'lastWeek' | 'lastMonth' | 'lastYear' | 'older';

export const useConversationsGroupedByDate = (conversations: ConversationSummaryResponse[]) => {
  return useMemo(
    () =>
      Object.entries(getConversationsGroupedByDate(conversations)).filter(
        (entry): entry is [ConversationsGroupDateKey, ConversationSummaryResponse[]] => entry[1].length > 0,
      ),
    [conversations],
  );
};

const getConversationsGroupedByDate = (
  conversations: ConversationSummaryResponse[],
): Partial<Record<ConversationsGroupDateKey, ConversationSummaryResponse[]>> => {
  const dates = Object.entries(getDate).map(([key, getDateFn]) => [key, getDateFn()]) as [
    ConversationsGroupDateKey,
    Date,
  ][];

  const conversationsGroupedByDate = {
    ...Object.fromEntries(dates.map(([key]) => [key, [] as ConversationSummaryResponse[]])),
    older: [] as ConversationSummaryResponse[],
  } as Record<ConversationsGroupDateKey, ConversationSummaryResponse[]>;

  conversations.forEach(conversation => {
    const dateGroup = findConversationDateGroup(conversation, dates);
    conversationsGroupedByDate[dateGroup].push(conversation);
  });

  return conversationsGroupedByDate;
};

const findConversationDateGroup = (
  conversation: ConversationSummaryResponse,
  dates: [ConversationsGroupDateKey, Date][],
): ConversationsGroupDateKey => {
  const conversationDate = new Date(conversation.updated_at);

  return (
    dates.find(([, date], dateGroupIndex, dateGroupArray) => {
      if (dateGroupIndex === 0) {
        return conversationDate >= date;
      }

      if (dateGroupIndex === dateGroupArray.length - 1) {
        return conversationDate < date;
      }

      const lastDate = dateGroupArray[dateGroupIndex - 1][1];
      return conversationDate >= date && conversationDate < lastDate;
    })?.[0] ?? 'older'
  );
};

const getDate = {
  today: () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  },
  yesterday: () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    return yesterday;
  },
  lastWeek: () => {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    lastWeek.setHours(0, 0, 0, 0);
    return lastWeek;
  },
  lastMonth: () => {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setHours(0, 0, 0, 0);
    return lastMonth;
  },
  lastYear: () => {
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    lastYear.setHours(0, 0, 0, 0);
    return lastYear;
  },
};
