import {useSidebar} from '#/hooks/use-sidebar.tsx';
import React, {FunctionComponent} from 'react';

const LayoutContentWrapper: FunctionComponent<{children: React.ReactNode}> = ({children}) => {
  const {isMobileVisible} = useSidebar();

  return (
    <div
      className='overflow-auto grow md:py-4 data-[mobilevisible=true]:invisible data-[mobilevisible=false]:visible md:data-[mobilevisible=true]:visible'
      data-mobilevisible={isMobileVisible}
    >
      {children}
    </div>
  );
};

export default LayoutContentWrapper;
