import App from '#/App';
import '#/index.css';
import '#/locales/i18n';
import reportWebVitals from '#/reportWebVitals';
import TimeAgo from 'javascript-time-ago';
import {createRoot} from 'react-dom/client';

import en from 'javascript-time-ago/locale/en';
import fr from 'javascript-time-ago/locale/fr';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(fr);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<App />);

reportWebVitals();
