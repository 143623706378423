import {useEffect} from 'react';
import {Location} from 'react-router-dom';

const useFocusPromptInputOnLocationChange = (
  promptInputRef: React.RefObject<HTMLTextAreaElement>,
  location: Location,
) => {
  useEffect(() => {
    promptInputRef.current?.focus();
  }, [promptInputRef, location.key]);
};

export default useFocusPromptInputOnLocationChange;
