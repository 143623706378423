import {MustUseWithinContextProviderError} from '#/hooks/MustUseWithinContextProviderError';
import React, {ReactNode, createContext, useContext, useState} from 'react';

interface ConversationsListEditContextType {
  openMenuUuid: string | null;
  setOpenMenuUuid: (uuid: string | null) => void;
  isEditing: string | null;
  setIsEditing: (uuid: string | null) => void;
  editedTitle: string;
  setEditedTitle: (title: string) => void;
}
const ConversationsListEditContext = createContext<ConversationsListEditContextType | undefined>(undefined);
export const ConversationsListEditContextProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState<string>('');
  const [openMenuUuid, setOpenMenuUuid] = useState<string | null>(null);

  return (
    <ConversationsListEditContext.Provider
      value={{
        openMenuUuid,
        setOpenMenuUuid,
        isEditing,
        setIsEditing,
        editedTitle,
        setEditedTitle,
      }}
    >
      {children}
    </ConversationsListEditContext.Provider>
  );
};
export const useConversationsListEdit = () => {
  const context = useContext(ConversationsListEditContext);

  if (context === undefined) {
    throw new MustUseWithinContextProviderError('useConversationsListItems', 'ConversationsListEditContextProvider');
  }
  return context;
};
