import {ProtectedImgProps} from '#/ProtectedImg.tsx';
import {CurrentUserAvatar} from '#/components/UserAvatar.tsx';
import AssistantAvatar from '#/components/assistants/AssistantAvatar.tsx';
import {Sender} from '#/components/chat-page/ChatPage.tsx';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {ReactComponent as ScoutIcon} from '#/resources/logo-round.svg';
import {FunctionComponent, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {twMerge} from 'tailwind-merge';

const ConversationImg: FunctionComponent<
  ProtectedImgProps & {
    role: Sender;
    assistant?: AssistantPublicResponse;
  }
> = ({role, assistant, className, containerClassName, ...props}) => {
  const {t} = useTranslation();

  const allClasses = `${className || ''} ${containerClassName || ''}`;

  if (role === 'user') {
    return <CurrentUserAvatar className={`text-sm ${allClasses}`} />;
  }

  if (role === 'assistant' && assistant?.name) {
    return (
      <AssistantAvatar
        {...props}
        src={assistant.avatar_url}
        assistantName={assistant.name}
        containerClassName={containerClassName}
        className={`size-full rounded-full ${className}`}
        alt={t('sidebar.conversations.assistant-avatar-alt')}
      />
    );
  }

  if (role === 'assistant' && !assistant?.name) {
    return <ScoutIcon className={twMerge(allClasses, 'text-accent')} />;
  }

  return null;
};

export default memo(ConversationImg);
