import DefaultToolUsedItem from '#/components/chat-page/tools-used/DefaultToolUsedItem.tsx';
import ExecuteCapabilityToolUsedItem from '#/components/chat-page/tools-used/ExecuteCapabilityToolUsedItem.tsx';
import ExecuteCodeToolUsedItem from '#/components/chat-page/tools-used/ExecuteCodeToolUsedItem.tsx';
import ExecuteExternalServicesCodeToolUsedItem from '#/components/chat-page/tools-used/ExecuteExternalServicesCodeToolUsedItem.tsx';
import GenerateImageContentToolUsedItem from '#/components/chat-page/tools-used/GenerateImageContentToolUsedItem.tsx';
import GetExternalServicesInstructionsToolUsedItem from '#/components/chat-page/tools-used/GetExternalServicesInstructionsToolUsedItem.tsx';
import GetFileTextContentToolUsedItem from '#/components/chat-page/tools-used/GetFileTextContentToolUsedItem.tsx';
import GetWebpageContentToolUsedItem from '#/components/chat-page/tools-used/GetWebpageContentToolUsedItem.tsx';
import GoogleSearchToolUsedItem from '#/components/chat-page/tools-used/GoogleSearchToolUsedItem.tsx';
import SearchCapabilitiesToolUsedItem from '#/components/chat-page/tools-used/SearchCapabilitiesToolUsedItem.tsx';
import SearchContentToolUsedItem from '#/components/chat-page/tools-used/SearchContentToolUsedItem.tsx';
import SearchLinksContentToolUsedItem from '#/components/chat-page/tools-used/SearchLinksContentToolUsedItem.tsx';
import {ToolCall} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {FunctionComponent, HTMLAttributes, ReactNode, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';

export interface ToolUsedItemProps {
  toolCall: ToolCall;
}

interface ToolUsedComponent {
  (props: ToolUsedItemProps): ReactNode;
}

const toolComponents: Record<string, ToolUsedComponent> = {
  execute_external_services_code: ExecuteExternalServicesCodeToolUsedItem,
  execute_capability: ExecuteCapabilityToolUsedItem,
  execute_python_code: ExecuteCodeToolUsedItem,
  generate_image: GenerateImageContentToolUsedItem,
  get_external_service_instructions: GetExternalServicesInstructionsToolUsedItem,
  get_file_text_content: GetFileTextContentToolUsedItem,
  get_webpage_content: GetWebpageContentToolUsedItem,
  google_web_search: GoogleSearchToolUsedItem,
  search_capabilities: SearchCapabilitiesToolUsedItem,
  search_files_content: SearchContentToolUsedItem,
  search_links_content: SearchLinksContentToolUsedItem,
};

const ToolUsedItem: FunctionComponent<{toolCall: ToolCall}> = ({toolCall}) => {
  return useMemo(() => {
    const Component = toolComponents[toolCall.tool_name] || DefaultToolUsedItem;
    return <Component toolCall={toolCall} />;
  }, [toolCall]);
};

export const ToolUsedItemWrapper: FunctionComponent<Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'>> = ({
  children,
  className,
}) => {
  return <div className={twMerge('bg-surface-02 px-4 py-2 rounded-lg', className)}>{children}</div>;
};

export default ToolUsedItem;
