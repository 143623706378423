import ActionBar from '#/components/layout/ActionBar.tsx';
import {useCheckAuthQuery} from '#/hooks/query/auth.tsx';
import {Navigate, Outlet} from 'react-router-dom';

const ProtectedLayout = () => {
  const checkAuthQuery = useCheckAuthQuery();

  if (checkAuthQuery.isLoading) {
    return <div className='bg-background size-full' />;
  }

  if (checkAuthQuery.isError) {
    return <Navigate to='/login' />;
  }

  return (
    <div className='layout-container size-full bg-background'>
      <div className='relative flex size-full'>
        <Outlet />

        <ActionBar />
      </div>
    </div>
  );
};

export default ProtectedLayout;
