import {useSidebar} from '#/hooks/use-sidebar.tsx';
import {FunctionComponent, HTMLAttributes} from 'react';

interface SidebarWrapperProps extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'children'> {
  alwaysCollapsed?: boolean;
}

const SidebarWrapper: FunctionComponent<SidebarWrapperProps> = ({className = '', children, alwaysCollapsed}) => {
  const {isMobileVisible, isDesktopCollapsed} = useSidebar();

  return (
    <div
      className={`absolute left-0 top-0 md:p-4 md:static overflow-y-auto data-[mobilevisible=true]:visible data-[mobilevisible=false]:invisible md:data-[mobilevisible=false]:visible z-20 size-full md:data-[desktopcollapsed=false]:w-[400px] md:data-[desktopcollapsed=true]:w-sidebar-actionbar-width flex flex-col justify-start shrink-0 bg-transparent duration-100 ease-in-out ${className}`}
      data-mobilevisible={isMobileVisible}
      data-desktopcollapsed={alwaysCollapsed ?? isDesktopCollapsed}
    >
      {children}
    </div>
  );
};

export default SidebarWrapper;
