import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {useTranslation} from 'react-i18next';

const ExecuteCodeToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <span>{t('tools.execute-python-code.in-progress-text')}</span>
    </div>
  );
};

export default ExecuteCodeToolLoader;
