import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

import useScrollToConversationOnConversationsChange from '#/hooks/sidebar/use-scroll-to-conversation-on-conversations-change.tsx';
import {useConversationsGroupedByDate} from '#/hooks/useConversationsGroupedByDate';
import {ConversationsListEditContextProvider} from '#/hooks/useConversationsListEdit';
import {ConversationsList} from '#/library/conversations-list/ConversationsList';
import React from 'react';
import {Flipper} from 'react-flip-toolkit';

type ConversationsListGroupsProps = React.HTMLAttributes<HTMLDivElement> & {
  conversations: ConversationSummaryResponse[];
};
export const ConversationsListGroups: FunctionComponent<ConversationsListGroupsProps> = ({
  conversations,
  className,
}) => {
  const {t} = useTranslation();
  const conversationsGroupedByDate = useConversationsGroupedByDate(conversations);

  useScrollToConversationOnConversationsChange(conversations);

  return (
    <ConversationsListEditContextProvider>
      <Flipper
        flipKey={conversations.map(conversation => conversation.id).join('')}
        className={`flex flex-col gap-5 px-4 md:px-0 ${className || ''}`}
      >
        {conversationsGroupedByDate.map(([key, value], index) => (
          <ConversationsList key={index} title={t(`sidebar.conversations.dateGroups.${key}`)} conversations={value} />
        ))}
      </Flipper>
    </ConversationsListEditContextProvider>
  );
};
