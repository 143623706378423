import {useSidebar} from '#/hooks/use-sidebar';
import {ReactComponent as PlusIcon} from '#/resources/plus-icon.svg';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

export const NewConversationTitleNavLink: FunctionComponent<{className?: string}> = ({className}) => {
  const {t} = useTranslation();
  const {mobileToggle} = useSidebar();

  return (
    <NavLink
      to='/chat'
      className={`flex items-center justify-center p-2 bg-surface-02 rounded-xl text-primary ${className || ''}`}
      onClick={mobileToggle}
    >
      <span className='grow text-base font-semibold pl-2'>{t('sidebar.conversations.title')}</span>
      <div className='h-9 aspect-square rounded-lg bg-accent text-accent-inverse flex justify-center items-center hover:opacity-70 transition-opacity'>
        <PlusIcon className='size-7 text-accent-inverse stroke-accent-inverse' />
      </div>
    </NavLink>
  );
};
