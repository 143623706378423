import {useEffect} from 'react';

const useResetSelectedIndexOnOpen = (isOpen: boolean, setSelectedIndex: (value: number) => void) => {
  useEffect(() => {
    if (isOpen) {
      setSelectedIndex(0);
    }
  }, [isOpen, setSelectedIndex]);
};

export default useResetSelectedIndexOnOpen;
