import {useAuthenticatedQuery} from '#/hooks/query/base.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import {useToasts} from '#/hooks/use-toasts.tsx';
import {fetchUserCustomPrompt} from '#/repositories/assistants-api/requests/fetch-user-custom-prompt.ts';
import {updateUserCustomPrompt} from '#/repositories/assistants-api/requests/update-user-custom-prompt.ts';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

export const useUserCustomPromptQuery = () =>
  useAuthenticatedQuery({
    queryKey: ['user-custom-prompt'],
    queryFn: async () => {
      const response = await fetchUserCustomPrompt();
      return response.data;
    },
  });

export const useUpdateUserCustomPromptMutation = () => {
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {user} = useAuth();
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['create-assistant'],
    mutationFn: updateUserCustomPrompt,
    onSuccess: ({data}) => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'user-custom-prompt']});
      addToast(t('settings.preferences.user-custom-prompt.toast.success'), 'success');
    },
    onError: error => {
      addToast(errorMessageForError(error), 'error');
    },
  });
};
