import {useDeleteConversationMutation} from '#/hooks/query/conversations.tsx';
import useExportRawConversation from '#/hooks/sidebar/use-export-raw-conversation.tsx';
import {useConversations} from '#/hooks/use-conversations';
import {
  SidebarDropdownMenuContent,
  SidebarDropdownMenuItem,
  SidebarDropdownMenuTrigger,
} from '#/library/sidebar/SidebarDropdownMenu';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {DownloadIcon, Pencil1Icon, TrashIcon} from '@radix-ui/react-icons';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export interface ConversationMoreMenuProps {
  uuid: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setIsEditing: (uuid: string | null) => void;
  setEditedTitle: (title: string) => void;
  currentTitle: string;
}

const ConversationMoreMenu = (props: ConversationMoreMenuProps) => {
  const {t} = useTranslation();

  const {uuid, isOpen, onOpenChange, setIsEditing, setEditedTitle, currentTitle} = props;
  const {activeConversationId} = useConversations();
  const navigate = useNavigate();
  const deleteConversationMutation = useDeleteConversationMutation();
  const exportRawConversation = useExportRawConversation(uuid);

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <SidebarDropdownMenuTrigger />

      <DropdownMenu.Portal>
        <SidebarDropdownMenuContent>
          <SidebarDropdownMenuItem
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              setIsEditing(uuid);
              setEditedTitle(currentTitle);
            }}
          >
            <Pencil1Icon width={18} height={18} />
            <span>{t('sidebar.conversations.actions.rename')}</span>
          </SidebarDropdownMenuItem>

          <SidebarDropdownMenuItem
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              exportRawConversation();
            }}
          >
            <DownloadIcon width={18} height={18} />
            <span>{t('sidebar.conversations.actions.export')}</span>
          </SidebarDropdownMenuItem>

          <SidebarDropdownMenuItem
            className='text-red-600'
            onClick={event => {
              event.preventDefault();
              deleteConversationMutation.mutate(uuid);

              if (activeConversationId === uuid) {
                navigate('/chat', {replace: true});
              }
            }}
          >
            <TrashIcon width={18} height={18} />
            <span>{t('sidebar.conversations.actions.delete')}</span>
          </SidebarDropdownMenuItem>

          <DropdownMenu.Arrow className='fill-surface-02' />
        </SidebarDropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default ConversationMoreMenu;
