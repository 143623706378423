import {ComponentProps, FunctionComponent} from 'react';
import {NavLink} from 'react-router-dom';

export const SidebarBoxNavLink: FunctionComponent<
  Omit<ComponentProps<typeof NavLink>, 'children'> & {
    children: {
      image: React.ReactNode;
      title: React.ReactNode;
      button: React.ReactNode;
    };
    dropdownOpen: boolean;
    isActive: boolean;
  }
> = ({children, isActive, dropdownOpen, ...props}) => {
  return (
    <NavLink
      {...props}
      className='group/conversation-navlink flex relative overflow-hidden gap-2 items-center rounded-lg bg-surface-02 hover:bg-surface-03 cursor-pointer mx-2 mr-1.5 px-2 py-2.5 active:bg-surface-03 data-[isactive=true]:bg-surface-03 data-[dropdownopen=true]:bg-surface-03'
      data-dropdownopen={dropdownOpen}
      data-isactive={isActive}
    >
      {children.image}
      <span className='grow overflow-hidden break-keep truncate'>{children.title}</span>
      {children.button}
    </NavLink>
  );
};
