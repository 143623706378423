import ProtectedImg from '#/ProtectedImg';
import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {useConfig} from '#/hooks/use-config';
import {castJson} from '#/utils/json-utils.ts';
import {useTranslation} from 'react-i18next';

interface ExecuteExternalServicesArguments {
  service_name: string;
}

const ExecuteExternalServiceCodeToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();
  const {config} = useConfig();

  const toolArguments = castJson<ExecuteExternalServicesArguments>(props.arguments);
  const externalService = config.features.externalServices.find(
    service => service.name === toolArguments?.service_name,
  );
  const serviceName = externalService?.display_name;
  const icon_url = externalService?.icon_url;

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <div className='flex items-center'>
        {!serviceName && t('tools.execute-external-services-code.writing_code')}
        {serviceName && t('tools.execute-external-services-code.calling', {serviceName})}
        {icon_url && (
          <ProtectedImg containerClassName='size-4 aspect-square ml-1' className='rounded-md' src={icon_url} />
        )}
      </div>
    </div>
  );
};

export default ExecuteExternalServiceCodeToolLoader;
