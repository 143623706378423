import {FunctionComponent, HTMLAttributes, createContext, useCallback, useContext, useRef} from 'react';

interface ConversationRefsContextType {
  registerRef: (id: string, element: HTMLDivElement | null) => void;
  scrollToConversation: (id: string) => void;
}

const ConversationRefsContext = createContext<ConversationRefsContextType | undefined>(undefined);

export const useConversationRefs = () => {
  const context = useContext(ConversationRefsContext);
  if (context === undefined) {
    throw new Error('useConversationRefs must be used within a ConversationRefsProvider');
  }
  return context;
};
type NullableElementRefs = {[key: string]: HTMLElement | null};

type ConversationRefsProviderProps = Pick<HTMLAttributes<HTMLElement>, 'children'>;

export const ConversationRefsProvider: FunctionComponent<ConversationRefsProviderProps> = ({children}) => {
  const conversationElementRefs = useRef<NullableElementRefs>({});

  const registerRef = useCallback((id: string, element: HTMLElement | null) => {
    conversationElementRefs.current[id] = element;
  }, []);

  const scrollToConversation = useCallback((id: string) => {
    const element = conversationElementRefs.current[id];
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  return (
    <ConversationRefsContext.Provider value={{registerRef, scrollToConversation}}>
      {children}
    </ConversationRefsContext.Provider>
  );
};
