import ProtectedAnchor from '#/ProtectedAnchor';
import {ExtraProps} from 'react-markdown';

const GeneratedAnchorComponent = ({className, node, ...props}: JSX.IntrinsicElements['a'] & ExtraProps) => (
  <ProtectedAnchor className={`${className || ''}`} {...props}>
    {props.children}
  </ProtectedAnchor>
);

export default GeneratedAnchorComponent;
