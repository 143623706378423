import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {castJson} from '#/utils/json-utils.ts';
import {useTranslation} from 'react-i18next';

export interface GoogleSearchArguments {
  query: string;
}

const GoogleSearchToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  const toolArguments = castJson<GoogleSearchArguments>(props.arguments);

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      {toolArguments ? (
        <span>{t('tools.google-search.in-progress-text-with-query', {query: toolArguments.query})}</span>
      ) : (
        <span>{t('tools.google-search.in-progress-text')}</span>
      )}
    </div>
  );
};

export default GoogleSearchToolLoader;
