import AssistantsAPI from '#/repositories/assistants-api/api';
import {fetchSignedUrl} from '#/repositories/assistants-api/requests/fetch-signed-url';
import environment from '#/repositories/environment';
import {AxiosResponse} from 'axios';

export type TaskRunStatus = 'IN_QUEUE' | 'RUNNING' | 'SUCCESS' | 'FAILED' | 'STOPPED';

export enum TaskRunStatusEnum {
  FAILED = 'FAILED',
  IN_QUEUE = 'IN_QUEUE',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
}

export type RunVariables = Record<string, string | number | boolean>;

export type RunLogResult = {
  result: string;
  status: 'SUCCESS' | 'ERROR';
  variables: RunVariables;
  step_description: string;
  exception?: string;
  exception_detail?: string;
};

export type TaskRunResponse = {
  id: string;
  task_id: string;
  task_description: string;
  updated_at: string;
  final_result?: RunVariables;
  run_log_url: string;
  status: TaskRunStatus;
};

export type TaskRunResponseWithLog = TaskRunResponse & {
  run_log: RunLogResult[];
};

export const fetchTaskRun = async (task_uuid: string, run_uuid: string): Promise<TaskRunResponseWithLog> => {
  const task_run_response = await AssistantsAPI.get<null, AxiosResponse<TaskRunResponse>>(
    `/tasks/${task_uuid}/runs/${run_uuid}`,
  );
  try {
    const signed_run_log_url = task_run_response.data.run_log_url;
    const signed_run_log_response = await fetchSignedUrl(signed_run_log_url);
    const signed_run_log_prefixed = signed_run_log_response.data.url.startsWith('/')
      ? environment.baseApiUrl + signed_run_log_response.data.url
      : signed_run_log_response.data.url;

    const run_log_response = await fetch(signed_run_log_prefixed);
    return {...task_run_response.data, run_log: await run_log_response.json()};
  } catch {
    return {...task_run_response.data, run_log: []};
  }
};
