import Page from '#/components/Page.tsx';
import ConversationComponent from '#/components/chat-page/ConversationComponent.tsx';
import {MENTION_ASSISTANT_REGEX} from '#/components/chat-page/MentionAssistantSelect.tsx';
import ToolStatusLoader from '#/components/tool-loaders/ToolStatusLoader.tsx';
import useFocusPromptInputOnLocationChange from '#/hooks/chat-page/use-focus-prompt-input-on-location-change.tsx';
import {usePrependUpdatedConversationIfNeeded} from '#/hooks/chat-page/use-prepend-updated-conversation-if-needed.tsx';
import {useRedirectOnInvalidConversation} from '#/hooks/chat-page/use-redirect-on-invalid-conversation.tsx';
import {useSelectConfig} from '#/hooks/chat-page/use-select-config.tsx';
import {useSendMessageFromBrowserExtension} from '#/hooks/chat-page/use-send-message-from-browser-extension.tsx';
import useSendMessageOnQuerySearchParam from '#/hooks/chat-page/use-send-message-on-query-search-param.tsx';
import {useSendMessagesOnNavigateWithNewMessages} from '#/hooks/chat-page/use-send-messages-on-navigate-with-new-messages.tsx';
import {useSetFormStateOnConversation} from '#/hooks/chat-page/use-set-form-state-on-conversation.tsx';
import useSetSelectedModelId from '#/hooks/chat-page/use-set-selected-model-id.tsx';
import {useSetSelectedModelWhenNotRecognized} from '#/hooks/chat-page/use-set-selected-model-when-not-recognized.tsx';
import {useStartStreamingOnNewConversation} from '#/hooks/chat-page/use-start-streaming-on-new-conversation.tsx';
import {usePublicAssistantQuery} from '#/hooks/query/assistants.tsx';
import {useChatModelsQuery} from '#/hooks/query/chat-models.tsx';
import {useConversationQuery, useCreateConversationMutation} from '#/hooks/query/conversations.tsx';
import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import Alert from '#/library/alert/Alert';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {ConversationMessage, ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation';
import {
  StreamChatCompletionRequest,
  StreamChunk,
  streamChatCompletion,
} from '#/repositories/assistants-api/requests/stream-chat-completion.ts';
import {LocalStorageKeys} from '#/repositories/environment.ts';
import {getTimeZoneOffset} from '#/utils/time-utils.ts';
import {FormEvent, FunctionComponent, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Location, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useLocalStorage} from 'react-use';

export type Sender = 'user' | 'assistant' | 'system' | 'tool';

type ChatPageParams = {
  conversationId: string;
  assistantId: string;
};

export type ChatPageState = {
  shouldStartStream: boolean;
  newMessages?: ConversationMessage[];
} | null;

export const ChatPage: FunctionComponent = () => {
  const {t} = useTranslation();
  const {convertErrorMessageToUserMessage} = useErrorMessage();

  const {conversationId, assistantId} = useParams<ChatPageParams>();
  const navigate = useNavigate();
  const location: Location<ChatPageState> = useLocation();

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<ConversationMessage[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const abortControllerRef = useRef<AbortController>();

  const promptInputRef = useRef<HTMLTextAreaElement>(null);
  useFocusPromptInputOnLocationChange(promptInputRef, location);

  const createConversationMutation = useCreateConversationMutation();
  const loading = createConversationMutation.isPending || isLoading;

  const conversationQuery = useConversationQuery({conversationId});
  const conversationIsLoading = conversationQuery.isLoading;
  useRedirectOnInvalidConversation(conversationQuery);
  useSetFormStateOnConversation(conversationQuery, setMessages, setIsLoading, setError, abortControllerRef);

  const [defaultModelId, setDefaultModelId] = useLocalStorage(LocalStorageKeys.SELECTED_MODEL_ID, '');
  const chatModels = useChatModelsQuery();
  const selectedModelId = conversationQuery.data?.model || defaultModelId;
  const setSelectedModelId = useSetSelectedModelId(conversationId, conversationQuery, setDefaultModelId);
  useSetSelectedModelWhenNotRecognized(chatModels, selectedModelId, setSelectedModelId);

  const prependUpdatedConversationIfNeeded = usePrependUpdatedConversationIfNeeded(conversationQuery.data);

  const publicAssistantQuery = usePublicAssistantQuery({assistantId});
  const assistant = conversationQuery.data?.assistant || publicAssistantQuery.data;
  const assistantIsLoading = publicAssistantQuery.isLoading;

  const [mentionedAssistant, setMentionedAssistant] = useState<AssistantPublicResponse | null>(null);

  const handleMentionedAssistantSelect = useCallback(
    (assistant: AssistantPublicResponse | null) => {
      setMentionedAssistant(assistant);
      setMessage(message.replace(MENTION_ASSISTANT_REGEX, ''));
    },
    [message],
  );

  const handleRemoveMentionedAssistant = useCallback(() => {
    setMentionedAssistant(null);
  }, []);

  const selectConfig = useSelectConfig(selectedModelId, setSelectedModelId, chatModels);

  const handleStreamOnMessage = useCallback(
    (streamChunk: StreamChunk, newConversation: ConversationMessage[]) => {
      const newConv = [...newConversation];

      const statusComponents =
        streamChunk.tool_calls?.map(tool_call => (
          <ToolStatusLoader toolName={tool_call.tool_name} status={tool_call.status} arguments={tool_call.arguments} />
        )) || [];

      if (streamChunk.error !== undefined) {
        // eslint-disable-next-line no-console
        console.error(streamChunk.error);
        statusComponents.push(
          <Alert variant='warning'>{convertErrorMessageToUserMessage(streamChunk.error.message)}</Alert>,
        );
      }

      if (streamChunk.content !== undefined) {
        newConv.push({role: 'assistant', content: streamChunk.content, metadata: streamChunk.metadata});
      }
      if (statusComponents.length > 0) {
        newConv.push({role: 'assistant', content: <div>{...statusComponents}</div>, metadata: streamChunk.metadata});
      }

      setMessages(newConv);
    },
    [convertErrorMessageToUserMessage],
  );

  const handleStopStreaming = useCallback(() => {
    setIsLoading(false);
    if (location.state?.shouldStartStream) {
      navigate('/chat/' + conversationId, {replace: true});
    }
  }, [navigate, location.state?.shouldStartStream, conversationId]);

  const handleStreamChatCompletion = useCallback(
    (newMessages: ConversationMessage[] | null, newConversation: ConversationMessage[]) => {
      setIsLoading(true);

      const request: StreamChatCompletionRequest = {
        new_messages: newMessages,
        model: selectedModelId || chatModels[0]?.id,
        mentioned_assistant_id: mentionedAssistant?.id,
      };

      const abortController = new AbortController();

      streamChatCompletion(
        conversationId || '',
        request,
        chunk => handleStreamOnMessage(chunk, newConversation),
        files,
        error => {
          if (!abortControllerRef.current?.signal.aborted) {
            setError(error.toString());
            setIsLoading(false);
          }
        },
        () => {
          handleStopStreaming();
        },
        abortController.signal,
      ).then(() => {
        prependUpdatedConversationIfNeeded();
      });

      setFiles([]);
      setMentionedAssistant(null);
      abortControllerRef.current = abortController;
    },
    [
      conversationId,
      selectedModelId,
      chatModels,
      mentionedAssistant?.id,
      files,
      handleStreamOnMessage,
      handleStopStreaming,
      prependUpdatedConversationIfNeeded,
    ],
  );

  useStartStreamingOnNewConversation(messages, handleStreamChatCompletion, location.state, conversationQuery);

  const sendMessage = useCallback(
    async (newMessages: ConversationMessage[], title: string | undefined = undefined) => {
      const newConversation = [...messages, ...newMessages];

      setError('');
      setMessage('');
      if (conversationId === undefined) {
        createConversationMutation.mutate({
          title,
          time_zone_offset: getTimeZoneOffset(),
          payload: newConversation,
          assistant_id: assistantId,
          model: selectedModelId || '',
        });
        setMessage('');
        return;
      }

      handleStreamChatCompletion(newMessages, newConversation);
      setMessages(newConversation);
    },
    [messages, conversationId, handleStreamChatCompletion, createConversationMutation, assistantId, selectedModelId],
  );

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isLoading) {
        abortControllerRef.current?.abort();
        handleStopStreaming();
        return;
      }

      setIsLoading(true);

      if (!selectedModelId) {
        return;
      }

      const newMessage: ConversationMessage = {
        role: 'user' as Sender,
        content: message,
        metadata: {
          files: files.map(file => ({name: file.name, content_type: file.type})),
        },
      };

      await sendMessage([newMessage]);
    },
    [files, handleStopStreaming, isLoading, message, selectedModelId, sendMessage],
  );

  useSendMessageFromBrowserExtension(sendMessage);

  useSendMessagesOnNavigateWithNewMessages(location.state, sendMessage, conversationQuery);

  useSendMessageOnQuerySearchParam(sendMessage, conversationId);

  const handleEditSubmit = useCallback(
    (updatedConversation: ConversationResponse) => {
      const updatedMessages = [...updatedConversation.payload];
      setMessages(updatedMessages);
      handleStreamChatCompletion(null, updatedMessages);
    },
    [handleStreamChatCompletion],
  );

  return (
    <Page title={t('conversation.page-title')}>
      <ConversationComponent
        conversationId={conversationId}
        messages={messages}
        conversationIsLoading={conversationIsLoading}
        isLoading={loading}
        message={message}
        onMessageChange={setMessage}
        files={files}
        onFileChange={setFiles}
        onSubmit={onSubmit}
        error={error}
        selectConfig={selectConfig}
        assistant={assistant}
        assistantIsLoading={assistantIsLoading}
        mentionedAssistant={mentionedAssistant}
        onMentionedAssistantSelect={handleMentionedAssistantSelect}
        onRemoveMentionedAssistant={handleRemoveMentionedAssistant}
        promptInputRef={promptInputRef}
        onEditSubmit={handleEditSubmit}
      />
    </Page>
  );
};
