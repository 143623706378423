import {MobileHeader} from '#/components/MobileHeader';
import Page from '#/components/Page';
import {TaskRunCard} from '#/components/tasks/LatestTasksSection';
import {useTaskQuery, useTaskRunsQuery} from '#/hooks/query/tasks';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {TaskRunStatusEnum} from '#/repositories/assistants-api/requests/fetch-task-run';
import {startTask} from '#/repositories/assistants-api/requests/start-task';
import {GearIcon} from '@radix-ui/react-icons';
import {useQueryClient} from '@tanstack/react-query';
import {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, NavLink, useParams} from 'react-router-dom';
import {ReactComponent as PlayIcon} from 'src/resources/play-icon.svg';

type TaskRunsPageParams = {
  taskId: string;
};

const TaskRunsPage: FunctionComponent = () => {
  const queryClient = useQueryClient();

  const {taskId} = useParams<TaskRunsPageParams>();
  const [shouldPoll, setShouldPoll] = useState(false);

  const {data: runs} = useTaskRunsQuery({taskId, shouldPoll});
  const {data: task} = useTaskQuery({taskId, shouldPoll: false});

  const {t} = useTranslation();

  const handleStartTaskClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!task) return;
    await startTask(task.id);
    queryClient.invalidateQueries({queryKey: ['task_runs', task.id]});
    setShouldPoll(true);
  };

  const pageTitle = task ? task.description : t('task-runs.page-title');

  useEffect(() => {
    setShouldPoll(
      !!!runs ||
        runs.some(
          taskRun => taskRun.status === TaskRunStatusEnum.IN_QUEUE || taskRun.status === TaskRunStatusEnum.RUNNING,
        ),
    );
  }, [runs]);

  return (
    <Page title={pageTitle}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{pageTitle}</h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch md:px-6 pb-3 md:pb-6 overflow-y-auto'>
        <PageContentHeader title={pageTitle}>
          <Link draggable={false} to='/tasks' className='text-xs text-secondary mr-4 text-nowrap'>
            {t('task-runs.actions.navigate-to-tasks')}
          </Link>
          {task && (
            <div className='flex gap-4'>
              <NavLink
                to={`/tasks/${taskId}/edit`}
                className='flex gap-2 h-12 rounded-lg bg-surface-02 p-4 items-center z-10 hover:opacity-70 transition-opacity group'
              >
                <GearIcon className='shrink-0 group-hover:rotate-[30deg] transition stroke-primary' />
                <span className='font-bold text-nowrap'>{t('task-runs.actions.edit-task')}</span>
              </NavLink>

              {task.status === 'PROCESSED' && (
                <button
                  className='h-12 flex items-center bg-accent text-accent-inverse rounded-xl font-bold px-4 py-2 gap-2 hover:opacity-70 transition-opacity'
                  onClick={handleStartTaskClick}
                >
                  <PlayIcon className='stroke-accent-inverse' />
                  {t('task-runs.actions.start')}
                </button>
              )}
            </div>
          )}
        </PageContentHeader>

        {runs && (
          <div className='max-w-page-content flex flex-col mx-auto gap-4 w-full'>
            {runs?.map(run => <TaskRunCard key={run.id} run={run} />)}
          </div>
        )}
      </div>
    </Page>
  );
};

export default TaskRunsPage;
