import TaskStepIcon from '#/components/tasks/create-edit-task/TaskStepIcon.tsx';
import {useConnectedServicesQuery} from '#/hooks/query/auth.tsx';
import {useExternalService} from '#/hooks/use-external-service.tsx';
import {CapabilityResponse} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {ReactComponent as GearIcon} from '#/resources/gear-icon.svg';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

const TaskCapabilitySection: FunctionComponent<{
  taskId?: string;
  capability?: CapabilityResponse;
  isOwner: boolean | undefined;
}> = ({taskId, capability, isOwner}) => {
  const {t} = useTranslation();

  const connectedServicesQuery = useConnectedServicesQuery();

  const serviceIsConnected = useCallback(
    (serviceName: string) => {
      return connectedServicesQuery.data?.data.services.some(service => service.name === serviceName);
    },
    [connectedServicesQuery.data?.data.services],
  );

  const {findExternalServiceByName} = useExternalService();

  const shouldDisplayFailedProcessing = !!capability && capability.task_plan.status !== 'SUCCESS';

  return (
    <div>
      <div className='w-full flex justify-between items-center mb-4 gap-4'>
        <span className='block text-primary font-bold'>{capability?.description}</span>
        <NavLink
          to={`/tasks/${taskId}/capabilities/${capability?.id}/steps/edit`}
          className='flex gap-2 rounded-lg bg-surface-02 py-3 px-4 items-center hover:opacity-70 transition-opacity group shrink-0'
        >
          <GearIcon className='group-hover:rotate-[30deg] transition stroke-primary' />
          <span className='font-bold'>
            {isOwner ? t('create-edit-task.actions.edit-steps') : t('create-edit-task.actions.view-steps')}
          </span>
        </NavLink>
      </div>

      <div
        className={
          (shouldDisplayFailedProcessing ? 'bg-warning' : 'bg-surface-02') +
          ' p-4 rounded-xl text-primary border border-stroke-main'
        }
      >
        {shouldDisplayFailedProcessing && (
          <div className='mb-4 font-bold'>{t('create-edit-task.fields.ai-detail.failed')}</div>
        )}
        {capability && (
          <div>
            {capability.task_plan.execution_plan.map((taskPlanItem, index) => (
              <div key={index}>
                <div className='flex mt-1'>
                  <TaskStepIcon taskPlanStep={taskPlanItem} className='size-6' />
                  <div className='ml-2' id={taskPlanItem.short_description}>
                    {taskPlanItem.short_description}
                  </div>
                </div>
                <div className='ml-8 text-xs text-accent'>{taskPlanItem.warning_message}</div>
                {!!taskPlanItem.code_execution?.external_service_name &&
                  !serviceIsConnected(taskPlanItem.code_execution?.external_service_name) && (
                    <NavLink to='/settings' className='ml-8 text-xs text-warning'>
                      {t('create-edit-task.fields.ai-detail.service-not-connected', {
                        serviceName: findExternalServiceByName(taskPlanItem.code_execution?.external_service_name)
                          ?.display_name,
                      })}
                    </NavLink>
                  )}
              </div>
            ))}
            <div className='bg-surface-03 p-4 rounded-xl mt-4'>{capability.task_plan.output_description}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskCapabilitySection;
