import {MustUseWithinContextProviderError} from '#/hooks/MustUseWithinContextProviderError';
import {LocalStorageKeys} from '#/repositories/environment.ts';
import React, {createContext, ReactNode, useContext} from 'react';
import {useLocalStorage} from 'react-use';

interface AppSettingsContextType {
  numberOfAssistantsToShow: number | undefined;
  setNumberOfAssistantsToShow: (value: number) => void;
  conversationMaxWidth: string | undefined;
  setConversationMaxWidth: (value: string) => void;
  useCasesViewEnabled: boolean | undefined;
  setUseCasesViewEnabled: (value: boolean) => void;
}

const AppSettingsContext = createContext<AppSettingsContextType | undefined>(undefined);

export const AppSettingsProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [numberOfAssistantsToShow, setNumberOfAssistantsToShow] = useLocalStorage(
    LocalStorageKeys.NUMBER_OF_ASSISTANTS_IN_SIDEBAR,
    4,
  );

  const [conversationMaxWidth, setConversationMaxWidth] = useLocalStorage(
    LocalStorageKeys.CONVERSATION_MAX_WIDTH,
    '800px',
  );

  const [useCasesViewEnabled, setUseCasesViewEnabled] = useLocalStorage(LocalStorageKeys.USE_CASES_VIEW_ENABLED, true);

  return (
    <AppSettingsContext.Provider
      value={{
        numberOfAssistantsToShow,
        setNumberOfAssistantsToShow,
        conversationMaxWidth,
        setConversationMaxWidth,
        useCasesViewEnabled,
        setUseCasesViewEnabled,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);

  if (context === undefined) {
    throw new MustUseWithinContextProviderError('useAppSettings', 'AppSettingsProvider');
  }
  return context;
};
