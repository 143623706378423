import {removeAllTokens} from '#/repositories/tokens_repository';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    removeAllTokens();
    navigate('/login');
  }, [navigate]);

  return <div />;
};

export default LogoutPage;
