import CreateEditAssistantPage from '#/components/CreateEditAssistantPage.tsx';
import LoginPage from '#/components/LoginPage.tsx';
import LogoutPage from '#/components/LogoutPage.tsx';
import ProtectedLayout from '#/components/ProtectedLayout.tsx';
import AdminPage from '#/components/admin/Admin.tsx';
import AllAssistantsPage from '#/components/assistants/AllAssistantsPage.tsx';
import AssistantsPage from '#/components/assistants/AssistantsPage.tsx';
import {ChatPage} from '#/components/chat-page/ChatPage.tsx';
import ConversationsLayout from '#/components/chat-page/ConversationsLayout.tsx';
import EmptySidebarLayout from '#/components/layout/EmptySidebarLayout.tsx';
import OktaLoginCallback from '#/components/login/okta/OktaLoginCallback.tsx';
import OktaLoginPage from '#/components/login/okta/OktaLoginPage.tsx';
import {OKTA_LOGIN_CALLBACK_URL, OKTA_LOGIN_URL} from '#/components/login/okta/OktaProvider.tsx';
import APITokensPage from '#/components/settings/APITokensPage';
import SettingsPage from '#/components/settings/SettingsPage.tsx';
import UserCustomPromptPage from '#/components/settings/UserCustomPromptPage.tsx';
import CreateEditTaskPage from '#/components/tasks/CreateEditTaskPage.tsx';
import TaskRunPage from '#/components/tasks/TaskRunPage.tsx';
import TaskRunsPage from '#/components/tasks/TaskRunsPage.tsx';
import TasksPage from '#/components/tasks/TasksPage.tsx';
import EditTaskStepsPage from '#/components/tasks/create-edit-task/EditTaskStepsPage.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {useConfig} from '#/hooks/use-config.tsx';
import {Navigate, Route, Routes} from 'react-router-dom';

const AppRoutes = () => {
  const {user} = useAuth();

  const {
    config: {
      features,
      integrations: {authentication},
    },
  } = useConfig();

  return (
    <Routes>
      <Route path='/' element={<Navigate to='/chat' replace />} />
      <Route path='/' element={<ProtectedLayout />}>
        <Route element={<EmptySidebarLayout />}>
          <Route path='settings/api-tokens' element={<APITokensPage />} />
          <Route path='settings/user-custom-prompt' element={<UserCustomPromptPage />} />
          <Route path='settings' element={<SettingsPage />} />

          {user?.isAdmin && <Route path='admin' element={<AdminPage />} />}

          {features.tasks && (
            <>
              <Route path='tasks/create' element={<CreateEditTaskPage />} />
              <Route path='tasks/runs' element={<TaskRunsPage />} />
              <Route path='tasks/:taskId/edit' element={<CreateEditTaskPage />} />
              <Route path='tasks/:taskId/capabilities/:capabilityId/steps/edit' element={<EditTaskStepsPage />} />
              <Route path='tasks/:taskId/runs' element={<TaskRunsPage />} />
              <Route path='tasks/:taskId/runs/:taskRunId' element={<TaskRunPage />} />
              <Route path='tasks' element={<TasksPage />} />
            </>
          )}
        </Route>

        <Route element={<ConversationsLayout />}>
          <Route path='chat/:conversationId' element={<ChatPage />} />

          <Route path='chat' element={<ChatPage />} />

          {features.assistants && (
            <>
              <Route path='assistants/all' element={<AllAssistantsPage />} />
              <Route path='assistants/create' element={<CreateEditAssistantPage />} />
              <Route path='assistants/:assistantId/edit' element={<CreateEditAssistantPage />} />
              <Route path='assistants/:assistantId/chat' element={<ChatPage />} />
              <Route path='assistants' element={<AssistantsPage />} />
            </>
          )}
        </Route>
      </Route>

      {authentication.okta && <Route path={OKTA_LOGIN_CALLBACK_URL} element={<OktaLoginCallback />} />}
      {authentication.okta && <Route path={OKTA_LOGIN_URL} element={<OktaLoginPage />} />}

      <Route path='/login' element={<LoginPage />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route path='*' element={<Navigate to='/chat' replace />} />
    </Routes>
  );
};

export default AppRoutes;
