import {KnowledgeFile} from '#/components/Files.tsx';
import {useCallback, useMemo} from 'react';

export const useInputFiles = (files: File[], onFileChange: (files: File[]) => void) => {
  const knowledgeFiles: KnowledgeFile[] = useMemo(() => {
    return files.map(file => ({id: file.name, name: file.name, status: 'PROCESSED', contentType: file.type}));
  }, [files]);

  const handleAddFiles = useCallback(
    (newFiles: File[]) => {
      const existingFileNames = new Set(files.map(file => file.name));
      const uniqueFiles = newFiles.filter(newFile => !existingFileNames.has(newFile.name));
      onFileChange([...files, ...uniqueFiles]);
    },
    [files, onFileChange],
  );

  const handleRemoveFile = useCallback(
    (index: number) => {
      onFileChange(files.filter((_, i) => i !== index));
    },
    [files, onFileChange],
  );

  return {knowledgeFiles, handleAddFiles, handleRemoveFile};
};
