import AssistantsAPI from '#/repositories/assistants-api/api.ts';
import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks';
import {ModelVisibilityRequest} from '#/repositories/assistants-api/requests/visibility.ts';
import {AxiosResponse} from 'axios';

export type CreatTaskRequest = {
  description: string;
  instructions: string;
  visibility: ModelVisibilityRequest;
};

export const createTask = (request: CreatTaskRequest): Promise<AxiosResponse<TaskResponse>> => {
  return AssistantsAPI.post<CreatTaskRequest, AxiosResponse<TaskResponse>>('/tasks/', request);
};
