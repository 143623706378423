import ProtectedImg from '#/ProtectedImg.tsx';
import {
  useConnectedServicesQuery,
  useExternalLogoutMutation,
  usePrepareExternalLoginMutation,
} from '#/hooks/query/auth.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {useConfig} from '#/hooks/use-config.tsx';
import {Button} from '#/library/button/Button.tsx';
import Label from '#/library/label/Label.tsx';
import {ExternalService} from '#/repositories/config.ts';
import environment from '#/repositories/environment.ts';
import {useQueryClient} from '@tanstack/react-query';
import {ComponentProps, FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

const ExternalServicesList: FunctionComponent = () => {
  const {t} = useTranslation();
  const {config} = useConfig();

  const connectedServicesQuery = useConnectedServicesQuery();

  const serviceIsConnected = useCallback(
    (serviceName: string) => {
      return connectedServicesQuery.data?.data.services.some(service => service.name === serviceName);
    },
    [connectedServicesQuery.data?.data.services],
  );

  if (config.features.externalServices.length === 0) {
    return null;
  }

  return (
    <div>
      <Label>{t('settings.connected-services.section-title')}</Label>
      <div className='flex flex-col gap-4 w-full'>
        {config.features.externalServices.map(service => (
          <ExternalServiceItem key={service.name} service={service} connected={serviceIsConnected(service.name)} />
        ))}
      </div>
    </div>
  );
};

const ExternalServiceItem: FunctionComponent<{service: ExternalService; connected: boolean | undefined}> = ({
  service,
  connected,
}) => {
  return (
    <div className='bg-surface-02 rounded-xl px-6 py-5 flex items-center justify-between'>
      <div className='flex items-center gap-3 w-[70%] '>
        <ProtectedImg containerClassName='size-6 aspect-square' className='rounded-md' src={service.icon_url} />
        {service.display_name}
      </div>
      {connected === false && <ExternalServiceLoginButton service={service} />}
      {connected === true && <ExternalServiceLogoutButton service={service} />}
    </div>
  );
};

const ExternalServiceLoginButton: FunctionComponent<{service: ExternalService}> = ({service}) => {
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  const prepareExternalLoginMutation = usePrepareExternalLoginMutation();
  const [windowIsShown, setWindowIsShown] = useState(false);

  const isPending = prepareExternalLoginMutation.isPending || windowIsShown;

  const handleClick = useCallback(async () => {
    const oauthWindow = window.open('about:blank', 'oauthWindow');

    try {
      const response = await prepareExternalLoginMutation.mutateAsync(service.name);
      if (oauthWindow) {
        oauthWindow.location = `${environment.baseApiUrl}/api/external/login?nonce=${response.data.nonce}`;
      }
    } catch (error) {
      if (oauthWindow) {
        oauthWindow.close();
      }
    }

    setWindowIsShown(true);

    const pollTimer = setInterval(() => {
      if (oauthWindow?.closed) {
        clearInterval(pollTimer);
        queryClient.invalidateQueries({queryKey: [user?.id, 'connected-services']});
        setWindowIsShown(false);
      }
    }, 200);

    return () => {
      clearInterval(pollTimer);
      setWindowIsShown(false);
    };
  }, [prepareExternalLoginMutation, queryClient, service.name, user?.id]);

  return (
    <ExternalServiceButton onClick={handleClick} isLoading={isPending} variant='primary'>
      {t('settings.connected-services.actions.login')}
    </ExternalServiceButton>
  );
};

const ExternalServiceLogoutButton: FunctionComponent<{service: ExternalService}> = ({service}) => {
  const {t} = useTranslation();

  const externalLogoutMutation = useExternalLogoutMutation();

  const handleClick = useCallback(async () => {
    await externalLogoutMutation.mutateAsync(service.name);
  }, [externalLogoutMutation, service]);

  return (
    <ExternalServiceButton onClick={handleClick} isLoading={externalLogoutMutation.isPending} variant='secondary'>
      {t('settings.connected-services.actions.logout')}
    </ExternalServiceButton>
  );
};

const ExternalServiceButton: FunctionComponent<Omit<ComponentProps<typeof Button>, 'size' | 'textSize'>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Button {...props} className={`rounded-xl max-w-[30%] ${className}`} textSize='base' size='sm'>
      {children}
    </Button>
  );
};

export default ExternalServicesList;
