import ScoutAPI from '#/repositories/assistants-api/api';
import {AxiosResponse} from 'axios';

export const fetchImage = (filePath: string, image_format?: string): Promise<AxiosResponse<Blob>> => {
  const url = new URL(`/protected/image/${filePath}`, window.location.origin);

  if (image_format) {
    url.searchParams.append('image_format', image_format);
  }

  return ScoutAPI.get(url.pathname + url.search, {
    responseType: 'blob',
  });
};
