import AssistantsAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export type UserPublicResponse = {
  first_name: string;
  last_name: string;
  email: string;
  picture_url: string | null;
};

export const fetchUsers = async (query: string): Promise<AxiosResponse<UserPublicResponse[]>> => {
  return AssistantsAPI.get<UserPublicResponse[]>('/users', {params: {query}});
};
