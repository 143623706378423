import {useExternalService} from '#/hooks/use-external-service.tsx';
import {TaskPlanExecutionStep} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {FunctionComponent, useMemo} from 'react';
import {ReactComponent as ScoutLogo} from 'src/resources/logo.svg';
import {ReactComponent as TaskIcon} from 'src/resources/tasks-icon.svg';
import {twMerge} from 'tailwind-merge';

const TaskStepIcon: FunctionComponent<{
  className?: string;
  taskPlanStep: TaskPlanExecutionStep;
}> = ({taskPlanStep, className}) => {
  const {findExternalServiceByName} = useExternalService();

  const externalService = useMemo(() => {
    if (!taskPlanStep.code_execution?.external_service_name) {
      return undefined;
    }

    return findExternalServiceByName(taskPlanStep.code_execution.external_service_name);
  }, [findExternalServiceByName, taskPlanStep.code_execution?.external_service_name]);

  return !!taskPlanStep.code_execution?.external_service_name ? (
    <img src={externalService?.icon_url} className={twMerge('rounded-md shrink-0', className)} alt='' />
  ) : taskPlanStep.type === 'CODE' ? (
    <TaskIcon className={twMerge('stroke-accent shrink-0', className)} />
  ) : (
    taskPlanStep.type === 'LLM' && <ScoutLogo className={twMerge('text-accent shrink-0', className)} />
  );
};

export default TaskStepIcon;
