import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

const useActiveAssistantId = () => {
  const location = useLocation();

  const activeAssistantId = useMemo(() => {
    const pathParts = location.pathname.split('/');
    const assistantsIndex = pathParts.indexOf('assistants');
    const shouldFilterByUUID = (part: string) => {
      return part !== 'create' && part !== 'all';
    };
    const uuid =
      assistantsIndex !== -1 &&
      pathParts.length > assistantsIndex + 1 &&
      shouldFilterByUUID(pathParts[assistantsIndex + 1])
        ? pathParts[assistantsIndex + 1]
        : null;
    return uuid;
  }, [location.pathname]);

  return activeAssistantId;
};

export default useActiveAssistantId;
