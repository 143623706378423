import {MobileHeader} from '#/components/MobileHeader';
import ProfileToggle from '#/components/ProfileToggle';
import {SidebarContextEnum} from '#/components/chat-page/ConversationsLayout';
import {useSidebar} from '#/hooks/use-sidebar.tsx';
import {useScreenWidth} from '#/hooks/useScreenWidth';
import {ActionBar} from '#/library/actionbar/ActionBar';
import {AssistantsTitleNavLink} from '#/library/sidebar/AssistantsTitleNavLink';
import BrandIconBox from '#/library/sidebar/BrandIconBox.tsx';
import {ConversationsListGroups} from '#/library/sidebar/ConversationsListGroups';
import {NewConversationTitleNavLink} from '#/library/sidebar/NewConversationTitleNavLink';
import ScoutIconBox from '#/library/sidebar/ScoutIconBox.tsx';
import ToggleDesktopSidebarButton from '#/library/sidebar/ToggleDesktopSidebarButton.tsx';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import {ReactComponent as PlusIcon} from '#/resources/plus-icon.svg';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

export type ConversationsSidebarProps = Partial<React.HTMLAttributes<HTMLDivElement>> & {
  conversations: ConversationSummaryResponse[];
  context: SidebarContextEnum;
};

export const ConversationsSidebar: FunctionComponent<ConversationsSidebarProps> = ({
  conversations,
  ...props
}: ConversationsSidebarProps) => {
  const isMd = useScreenWidth('md');

  if (!isMd) {
    return <MobileConversationsSidebar conversations={conversations} {...props} />;
  }

  return <DesktopConversationsSidebar conversations={conversations} {...props} />;
};

export const MobileConversationsSidebar: FunctionComponent<ConversationsSidebarProps> = ({
  conversations,
  context,
  className = '',
  ...props
}) => {
  const {t} = useTranslation();
  const {mobileToggle} = useSidebar();

  return (
    <div {...props} className={`size-full min-h-fit bg-surface-01 overflow-y-auto flex flex-col gap-5 ${className}`}>
      <MobileHeader>
        <span className='text-primary font-bold'>{t('sidebar.header')}</span>

        <NavLink
          to='/chat'
          className='flex items-center h-12 aspect-square rounded-md bg-accent text-accent-inverse font-semibold justify-center hover:opacity-70 transition-opacity'
          onClick={mobileToggle}
        >
          <PlusIcon className='size-7 stroke-accent-inverse' />
        </NavLink>
      </MobileHeader>

      <ActionBar variant='horizontal' className='justify-between shrink-0 mx-4' />

      {context === SidebarContextEnum.Chat && <NewConversationTitleNavLink className='justify-between shrink-0 mx-4' />}

      {context === SidebarContextEnum.Assistants && (
        <AssistantsTitleNavLink className='justify-between shrink-0 mx-4' />
      )}

      <ConversationsListGroups conversations={conversations} />

      <div className='w-full absolute bottom-0 flex flex-col gap-5 left-0 px-4 pt-4 bg-surface-01'>
        <ProfileToggle />
        <ScoutIconBox variant='expanded' />
      </div>
    </div>
  );
};

export const DesktopConversationsSidebar: FunctionComponent<ConversationsSidebarProps> = ({
  conversations,
  className = '',
  ...props
}) => {
  const {isDesktopCollapsed} = useSidebar();

  return (
    <div
      {...props}
      className={`h-full min-h-fit overflow-y-auto flex flex-col gap-y-4 p-4 pb-0 rounded-2xl bg-surface-01 border border-stroke-main group/sidebar-container ${className}`}
      data-desktopcollapsed={isDesktopCollapsed}
    >
      <div className='flex items-center justify-center'>
        <BrandIconBox
          variant='expanded'
          className='mr-auto group-data-[desktopcollapsed=true]/sidebar-container:hidden'
        />

        <ToggleDesktopSidebarButton />
      </div>

      {isDesktopCollapsed && <BrandIconBox variant='collapsed' />}

      <NewConversationTitleNavLink className='group-data-[desktopcollapsed=true]/sidebar-container:hidden' />

      <ConversationsListGroups
        conversations={conversations}
        className='group-data-[desktopcollapsed=true]/sidebar-container:hidden'
      />

      <div className='w-full sticky bottom-0 left-0 mt-auto pointer-events-none'>
        <div className='w-full h-5 bg-gradient-to-t from-surface-01 pb-3 pointer-events-none' />

        <ScoutIconBox variant={isDesktopCollapsed ? 'collapsed' : 'expanded'} />
      </div>
    </div>
  );
};
