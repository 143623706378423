import Files, {KnowledgeFile} from '#/components/Files.tsx';
import Markdown from '#/components/Markdown.tsx';
import ConversationImg from '#/components/chat-page/ConversationImg.tsx';
import CopyToClipboardButton from '#/components/chat-page/CopyToClipboardButton';
import EditButtonsView from '#/components/chat-page/EditMessageButtonsView.tsx';
import EditMessageTextarea from '#/components/chat-page/EditMessageTextarea.tsx';
import ToolsUsedView from '#/components/chat-page/tools-used/ToolsUsedView.tsx';
import useEditMessage from '#/hooks/chat-page/use-edit-message.tsx';
import {useAssistants} from '#/hooks/use-assistants.tsx';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {ConversationMessage, ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {Pencil1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, HTMLProps, memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const Message: FunctionComponent<
  {
    message: ConversationMessage;
    index: number;
    conversationAssistantNameToDisplay: string;
    conversationAssistant?: AssistantPublicResponse;
    conversationId: string | undefined;
    onEditSubmit: (updatedConversation: ConversationResponse) => void;
  } & HTMLProps<HTMLDivElement>
> = ({
  message,
  index,
  conversationAssistantNameToDisplay,
  conversationAssistant,
  conversationId,
  onEditSubmit,
  ...props
}) => {
  const {t} = useTranslation();

  const {assistants} = useAssistants();

  const assistant = useMemo(() => {
    if (message.metadata?.assistant_id) {
      return assistants.find(assistant => assistant.id === message.metadata?.assistant_id);
    }
    return conversationAssistant;
  }, [assistants, conversationAssistant, message.metadata?.assistant_id]);

  const files: KnowledgeFile[] | undefined = useMemo(() => {
    return message.metadata?.files?.map(file => ({
      id: file.name,
      name: file.name,
      status: 'PROCESSED',
      contentType: file.content_type,
    }));
  }, [message.metadata?.files]);

  const assistantNameToDisplay = useMemo(() => {
    return assistant?.name || conversationAssistantNameToDisplay;
  }, [assistant?.name, conversationAssistantNameToDisplay]);

  const {
    isEditing,
    editedMessage,
    handleEditClick,
    onEditMessageChange,
    handleEditSubmit,
    handleEditCancel,
    editIsPending,
  } = useEditMessage(message, index, conversationId, onEditSubmit);

  return (
    <div {...props} className='w-full relative group/message overflow-hidden' tabIndex={1}>
      <div className='relative flex gap-4 grow overflow-hidden w-full  '>
        <ConversationImg
          role={message.role}
          assistant={assistant}
          containerClassName='size-6 shrink-0 mt-2.5'
          className='rounded-full shrink-0 text-base'
          loading='lazy'
          decoding='async'
        />

        <div className='grow space-y-1 overflow-hidden'>
          <div className='align-top text-primary text-lg font-bold'>
            {message.role === 'user' ? t('conversation.user-title') : assistantNameToDisplay}
          </div>

          <ToolsUsedView metadata={message.metadata} />

          {message.role === 'assistant' && typeof message.content === 'string' ? (
            <Markdown>{message.content}</Markdown>
          ) : isEditing ? (
            <EditMessageTextarea editedMessage={editedMessage} onChange={onEditMessageChange} />
          ) : (
            <div className='break-words whitespace-pre-wrap text-primary'>{message.content}</div>
          )}

          {files && <Files files={files} className='flex gap-2' />}

          {isEditing ? (
            <EditButtonsView isPending={editIsPending} onSubmit={handleEditSubmit} onCancel={handleEditCancel} />
          ) : (
            <div className='flex gap-2 invisible group-focus-within/message:visible group-hover/message:visible'>
              <CopyToClipboardButton content={message} />
              {message.role === 'user' && (
                <button className='size-6 rounded-lg' onClick={handleEditClick}>
                  <Pencil1Icon className='stroke-secondary hover:text-primary hover:stroke-primary' />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Message);
