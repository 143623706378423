import {useEffect} from 'react';

const useSetOpenWhenSearchNotNull = (search: string | null, setIsOpen: (isOpen: boolean) => void) => {
  useEffect(() => {
    if (search === null) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [search, setIsOpen]);
};

export default useSetOpenWhenSearchNotNull;
