import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {castJson} from '#/utils/json-utils.ts';
import {useTranslation} from 'react-i18next';

export interface SearchContentArguments {
  prompt: string;
}

const SearchContentToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  const toolArguments = castJson<SearchContentArguments>(props.arguments);

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      {toolArguments ? (
        <span>{t('tools.search-content.in-progress-text-with-query', {query: toolArguments.prompt})}</span>
      ) : (
        <span>{t('tools.search-content.in-progress-text')}</span>
      )}
    </div>
  );
};
export default SearchContentToolLoader;
