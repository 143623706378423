import ScoutAPI from '#/repositories/assistants-api/api';
import {ConversationMessage, ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {AxiosResponse} from 'axios';

export type CreateConversationRequest = {
  payload: ConversationMessage[];
  time_zone_offset: string;
  assistant_id?: string;
  title?: string;
  model: string;
};

export const createConversation = (
  conversation: CreateConversationRequest,
): Promise<AxiosResponse<ConversationResponse>> => {
  return ScoutAPI.post<CreateConversationRequest, AxiosResponse<ConversationResponse>>('/conversations/', conversation);
};
