import GeneratedAnchorComponent from '#/components/chat-page/GeneratedAnchorComponent.tsx';
import GeneratedCodeComponent from '#/components/chat-page/GeneratedCodeComponent';
import GeneratedImageComponent from '#/components/chat-page/GeneratedImageComponent.tsx';
import 'katex/dist/katex.min.css';
import {memo} from 'react';
import ReactMarkdown, {Options} from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import {twMerge} from 'tailwind-merge';

const remarkPlugins = [remarkParse, remarkRehype, remarkGfm, rehypeRaw, remarkMath];
const rehypePlugins = [rehypeHighlight, rehypeRaw, rehypeKatex];

const markdownComponents = {img: GeneratedImageComponent, a: GeneratedAnchorComponent, pre: GeneratedCodeComponent};
const Markdown = ({className, ...props}: Readonly<Options>) => {
  return (
    <ReactMarkdown
      className={twMerge('conversation-markdown break-words text-primary', className)}
      rehypePlugins={rehypePlugins}
      remarkPlugins={remarkPlugins}
      components={markdownComponents}
      {...props}
    />
  );
};

export default memo(Markdown);
