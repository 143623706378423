import {useSidebar} from '#/hooks/use-sidebar.tsx';
import {ReactComponent as ExpandIcon} from '#/resources/expand.svg';
import {FunctionComponent} from 'react';

const ToggleDesktopSidebarButton: FunctionComponent = () => {
  const {isDesktopCollapsed, desktopToggle} = useSidebar();

  return (
    <button className='border border-stroke-main rounded-lg p-2' onClick={desktopToggle}>
      <ExpandIcon
        className='stroke-secondary size-5 data-[desktopcollapsed=false]:rotate-180'
        data-desktopcollapsed={isDesktopCollapsed}
      />
    </button>
  );
};

export default ToggleDesktopSidebarButton;
