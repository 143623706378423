import translationEn from '#/locales/en/translation.json';
import translationFr from '#/locales/fr/translation.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

export const defaultNS = 'translation';

export const resources = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS,
    resources,
    debug: false,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

document.addEventListener('DOMContentLoaded', () => {
  const isI18nLngInvalidated = localStorage.getItem('i18nextLngInvalidated');

  if (!isI18nLngInvalidated) {
    localStorage.removeItem('i18nextLng');

    localStorage.setItem('i18nextLngInvalidated', 'true');

    window.location.reload();
  }
});

export default i18n;
