import {FunctionComponent, memo} from 'react';

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  variant: 'info' | 'warning';
}

const Alert: FunctionComponent<AlertProps> = memo(({children, variant, className}) => {
  return (
    <div
      className={`data-[variant='info']:info-alert data-[variant='warning']:warning-alert border p-4 my-2 w-full rounded-md ${className || ''}`}
      data-variant={variant}
    >
      {children}
    </div>
  );
});

export default Alert;
