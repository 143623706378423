import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {useTranslation} from 'react-i18next';

const ExecuteCapabilityToolLoader = () => {
  const {t} = useTranslation();

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />

      <span>{t('tools.execute-capability.in-progress-text')}</span>
    </div>
  );
};
export default ExecuteCapabilityToolLoader;
