import AssistantsAPI from '#/repositories/assistants-api/api';
import {TaskResponse} from '#/repositories/assistants-api/requests/fetch-tasks';
import {ModelVisibilityRequest} from '#/repositories/assistants-api/requests/visibility.ts';
import {AxiosResponse} from 'axios';

export type UpdateTaskRequest = {
  description: string;
  instructions: string;
  variables: Record<string, string>;
  visibility: ModelVisibilityRequest;
};

export const updateTask = (id: string, request: UpdateTaskRequest): Promise<AxiosResponse<TaskResponse>> => {
  return AssistantsAPI.put<UpdateTaskRequest, AxiosResponse<TaskResponse>>(`/tasks/${id}`, request);
};
