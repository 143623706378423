import ScoutAPI from '#/repositories/assistants-api/api';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {AxiosResponse} from 'axios';

export type UpdateConversationRequest = {
  title?: string;
  model?: string;
};

export const updateConversation = (id: string, conversation: UpdateConversationRequest) =>
  ScoutAPI.put<UpdateConversationRequest, AxiosResponse<ConversationSummaryResponse>>(
    `/conversations/${id}`,
    conversation,
  );
