import PythonCodeToolUsed from '#/components/chat-page/tools-used/PythonCodeToolUsed.tsx';
import {ToolUsedItemProps, ToolUsedItemWrapper} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {castJson} from '#/utils/json-utils.ts';
import {CodeIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface ExecuteCodeToolArguments {
  code: string;
  service_name: string;
}
const ExecuteCodeToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    const toolArguments = castJson<ExecuteCodeToolArguments>(toolCall.arguments);
    return toolArguments;
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='border-b border-secondary flex items-center gap-2 pb-2'>
        <CodeIcon className='inline-block size-4 text-accent shrink-0' />
        <span>{t('conversation.tool-used.tools.execute-code')}</span>
      </div>
      <PythonCodeToolUsed code={toolArguments?.code} />
    </ToolUsedItemWrapper>
  );
};

export default ExecuteCodeToolUsedItem;
