import {useCallback} from 'react';

const useDownloadBlob = () => {
  return useCallback((blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, []);
};

export default useDownloadBlob;
