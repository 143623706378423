import {useConfig} from '#/hooks/use-config.tsx';
import {useCallback} from 'react';

export const useExternalService = () => {
  const {config} = useConfig();

  const findExternalServiceByName = useCallback(
    (serviceName: string) => {
      return config.features.externalServices.find(service => service.name === serviceName);
    },
    [config.features.externalServices],
  );

  return {findExternalServiceByName};
};
