const theming = {
  black: '#141416',
  white: '#FFFFFF',
  grey: {
    100: '#FCFCFC',
    200: '#F4F5F6',
    300: '#E0E0E0',
    400: '#E6E8EC',
    500: '#B1B5C3',
    600: '#9093A1',
    700: '#353945',
    800: '#23262F',
    900: '#18191D',
    1000: '#292929',
  },
};

const light = {
  background: theming.grey[200],
  primary: theming.black,
  secondary: theming.grey[600],
  inverse: theming.grey[800],
  stroke: {
    main: theming.grey[400],
  },
  surface: {
    '01': theming.white,
    '02': theming.grey[200],
    '03': theming.grey[400],
  },
  info: {
    background: '#cce5ff',
    inverse: '#004085',
  },
  warning: {
    background: '#fff3cd',
    inverse: '#856404',
  },
  danger: {
    background: '#ed6A5f',
    inverse: '#ff4524',
  },
};

type Theme = typeof light;

const dark: Theme = {
  background: theming.black,
  primary: theming.white,
  secondary: theming.grey[500],
  inverse: theming.black,
  stroke: {
    main: theming.grey[700],
  },
  surface: {
    '01': theming.grey[900],
    '02': theming.grey[800],
    '03': theming.grey[700],
  },
  info: {
    background: '#004085',
    inverse: '#cce5ff',
  },
  warning: {
    background: '#856404',
    inverse: '#fff3cd',
  },
  danger: {
    background: '#ed6A5f',
    inverse: '#ff4524',
  },
};

export {dark, light};
