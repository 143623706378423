import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {DotsHorizontalIcon} from '@radix-ui/react-icons';
import {ComponentProps, FunctionComponent, forwardRef} from 'react';

export const SidebarDropdownMenuTrigger: FunctionComponent<ComponentProps<typeof DropdownMenu.Trigger>> = forwardRef(
  (props, ref) => (
    <DropdownMenu.Trigger
      {...props}
      ref={ref}
      className='p-1 md:p-0 flex justify-center items-center md:absolute md:top-0 md:right-0 md:h-full md:invisible data-[state=open]:visible active:visible md:group-hover/conversation-navlink:visible'
    >
      <div className='hidden md:block h-full w-4 bg-gradient-to-l from-surface-03' />
      <div className='md:pl-3 md:pr-5 md:inline-flex md:items-center md:h-full md:bg-surface-03 group/button'>
        <DotsHorizontalIcon className='size-4 group-hover/button:opacity-30' />
      </div>
    </DropdownMenu.Trigger>
  ),
);

export const SidebarDropdownMenuContent: FunctionComponent<ComponentProps<typeof DropdownMenu.Content>> = forwardRef(
  ({children, ...props}, ref) => (
    <DropdownMenu.Content
      {...props}
      ref={ref}
      className='min-w-[200px] p-1.5 z-30 bg-surface-01 rounded-md shadow-lg text-primary'
      align='end'
      sideOffset={5}
      alignOffset={0}
    >
      {children}
      <DropdownMenu.Arrow className='fill-surface-01' />
    </DropdownMenu.Content>
  ),
);

export const SidebarDropdownMenuItem: FunctionComponent<ComponentProps<typeof DropdownMenu.Item>> = forwardRef(
  ({children, className = '', ...props}, ref) => (
    <DropdownMenu.Item
      {...props}
      ref={ref}
      className={`flex items-center gap-3 p-2 text-sm rounded-md cursor-pointer hover:bg-surface-02 outline-none ${className}`}
    >
      {children}
    </DropdownMenu.Item>
  ),
);
