import ScoutAPI from '#/repositories/assistants-api/api';
import {checkAuth} from '#/repositories/assistants-api/requests/check-auth.ts';
import {ConversationMessage, MessageMetadata} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {getAccessToken} from '#/repositories/tokens_repository';

export const KEEP_ALIVE_CHARS_REGEX = /:\n\n/g;

export type StreamChatCompletionRequest = {
  new_messages: ConversationMessage[] | null;
  model: string;
  mentioned_assistant_id?: string;
};

export type StreamToolStatus = {
  status: 'PREPARING' | 'CALLING';
  tool_name: string;
  arguments: string;
};

export type StreamError = {
  code: string;
  message: string;
};

export type StreamChunk = {
  finish_reason?: 'STOP' | 'ERROR';
  content?: string;
  tool_calls?: StreamToolStatus[];
  metadata?: MessageMetadata;
  error: StreamError;
};

const updateAccessToken = (options: RequestInit) => {
  const access_token = getAccessToken();

  return {
    ...options.headers,
    Authorization: `Bearer ${access_token}`,
  };
};

const fetchWithTokenRefresh = async (url: string, options: RequestInit) => {
  options.headers = updateAccessToken(options);

  let response = await fetch(url, {...options});

  if (response.status === 401) {
    try {
      await checkAuth();
      options.headers = updateAccessToken(options);
      response = await fetch(url, {...options});
    } catch (refreshError) {
      throw refreshError;
    }
  }

  if (!response.ok) {
    const errorBody = await response.json();
    throw new Error(errorBody.error);
  }

  return response;
};

export type StreamChatCompletionOnMessageEvent = (streamChunk: StreamChunk) => void;

export const streamChatCompletion = async (
  conversationId: string,
  request: StreamChatCompletionRequest,
  onMessage: StreamChatCompletionOnMessageEvent,
  files: File[],
  onError: (error: string) => void,
  onStreamEnd: () => void,
  signal: AbortSignal,
) => {
  const formData = new FormData();
  files?.length && files.forEach(file => formData.append(file.name, file));
  formData.append('chat_request', JSON.stringify(request));

  try {
    const response = await fetchWithTokenRefresh(
      `${ScoutAPI.defaults.baseURL}/conversations/${conversationId}/completion/`,
      {
        method: 'POST',
        body: formData,
        signal,
      },
    );
    if (!response.ok) {
      onError('An unexpected error occurred');
      return;
    }
    if (response.body === null) {
      onError('ReadableStream not supported in this browser');
      return;
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let accumulatedMessage = '';

    return new ReadableStream({
      async start(controller) {
        try {
          let accumulatedCurrentData = '';
          while (true) {
            const {done, value} = await reader.read();
            if (done) {
              onStreamEnd();
              break;
            }

            const currentData =
              accumulatedCurrentData + decoder.decode(value, {stream: true}).replace(KEEP_ALIVE_CHARS_REGEX, '');
            accumulatedCurrentData = '';
            // When data does not finish with a \n, we must accumulate it as the JSON chunck is cut in two
            if (!currentData.endsWith('\n')) {
              accumulatedCurrentData += currentData;
              continue;
            }
            if (!currentData) continue;

            const chunks = currentData.split('\n');
            const parsedStreamChunk = chunks.reduce(
              (acc, chunk) => (!chunk ? acc : [...acc, JSON.parse(chunk)]),
              [] as StreamChunk[],
            );

            const mergedChunk = parsedStreamChunk.reduce(
              (acc, chunk) => {
                const contentToAppend = chunk.finish_reason ? '' : chunk.content || '';
                return {...acc, ...chunk, content: (acc.content || '') + contentToAppend};
              },
              {content: accumulatedMessage} as StreamChunk,
            );
            accumulatedMessage = mergedChunk.content || '';

            onMessage(mergedChunk);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          onError('An unexpected error occurred, try refreshing the page.');
        }
      },
    });
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    onError(errorMessage);
  }
};
