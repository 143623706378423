import {SelectConfig} from '#/components/chat-page/ConversationComponent.tsx';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {ChevronDownIcon} from '@radix-ui/react-icons';
import {memo} from 'react';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

export interface ModelSelectFieldProps {
  selectConfig: SelectConfig;
  className?: string;
}

const ModelSelectField = (props: ModelSelectFieldProps) => {
  const {
    selectConfig: {value, onChange, options},
    className,
  } = props;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        className={`flex items-center justify-between gap-2 px-4 py-3 w-fit text-base font-bold text-primary rounded-lg text-center truncate focus:outline-none bg-surface-02 hover:shadow transition-shadow shrink-0 ${className}`}
      >
        {options.find(option => option.id === value)?.value}
        <ChevronDownIcon />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className='w-[300px] border-stroke-main border bg-surface-02 p-2 rounded-xl shadow-lg text-primary z-30 max-h-[60vh] overflow-y-auto'
          sideOffset={5}
        >
          <DropdownMenu.RadioGroup value={value} onValueChange={onChange}>
            {options.map((option, index) => (
              <ScrollIntoViewIfNeeded
                active={option.id === value}
                options={{behavior: 'instant', block: 'start'}}
                key={option.id}
              >
                <DropdownMenu.RadioItem
                  key={option.id}
                  value={option.id}
                  className='flex items-center gap-4 p-2 rounded-md cursor-pointer hover:bg-surface-03 transition-colors focus:outline-none'
                >
                  <div className='flex items-center grow w-[240px]'>
                    <div className='flex flex-col'>
                      <div className='font-semibold'>{option.value}</div>
                      <div className='text-sm text-primary'>{option.description}</div>
                    </div>
                  </div>
                  <div className='relative size-6 border-secondary border-2 rounded-full overflow-hidden p-1'>
                    <DropdownMenu.ItemIndicator>
                      <div className='bg-accent size-full rounded-full overflow-hidden' />
                    </DropdownMenu.ItemIndicator>
                  </div>
                </DropdownMenu.RadioItem>
                {index !== options.length - 1 && <DropdownMenu.Separator className='my-3 mx-2 h-px bg-surface-03' />}
              </ScrollIntoViewIfNeeded>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default memo(ModelSelectField);
