import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export type AssistantPublicResponse = {
  id: string;
  name: string;
  description: string;
  visibility: string;
  is_owner: boolean;
  is_collaborator: boolean;
  owner_name: string;
  prompt_starters: string[];
  avatar_url?: string;
};

export const fetchAssistants = (): Promise<AxiosResponse<AssistantPublicResponse[]>> => {
  return ScoutAPI.get<AssistantPublicResponse[]>('/assistants');
};
