import AssistantsAPI from '#/repositories/assistants-api/api';
import {TaskRunResponse} from '#/repositories/assistants-api/requests/fetch-task-run';
import {AxiosResponse} from 'axios';

export const fetchAllTaskRuns = (
  status?: string,
  skip?: number,
  limit?: number,
): Promise<AxiosResponse<TaskRunResponse[]>> => {
  const params = {
    status,
    skip,
    limit,
  };
  return AssistantsAPI.get<null, AxiosResponse<TaskRunResponse[]>>('/tasks/all_runs', {params});
};
