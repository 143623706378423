import ScoutSpinner from '#/components/ScoutSpinner.tsx';
import {
  useCreateTaskScheduleMutation,
  useDeleteTaskScheduleMutation,
  useTaskSchedulesQuery,
} from '#/hooks/query/tasks.tsx';
import Alert from '#/library/alert/Alert.tsx';
import {Button} from '#/library/button/Button.tsx';
import Label from '#/library/label/Label.tsx';
import {Textarea} from '#/library/textarea/Textarea.tsx';
import {TaskScheduleResponse} from '#/repositories/assistants-api/requests/create-task-schedule';
import {ReactComponent as CheckmarkIcon} from '#/resources/checkmark-icon.svg';
import {ReactComponent as TrashIcon} from '#/resources/trash-icon.svg';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type CreateEditSchedulesSectionProps = {
  taskId?: string;
};

const CreateEditSchedulesSection = (props: CreateEditSchedulesSectionProps) => {
  const {taskId} = props;
  const {t} = useTranslation();
  const [schedules, setSchedules] = useState<TaskScheduleResponse[]>([]);
  const [pendingScheduleIndex, setPendingScheduleIndex] = useState(-1);

  const taskScheduleQuery = useTaskSchedulesQuery({taskId});

  useEffect(() => {
    if (taskScheduleQuery.data) {
      setSchedules(taskScheduleQuery.data);
    } else {
      setSchedules([]);
    }
  }, [taskScheduleQuery.data]);

  const createTaskScheduleMutation = useCreateTaskScheduleMutation();

  const handleAddTaskSchedule = useCallback(
    async (schedulePrompt: string, index: number) => {
      if (schedules[schedules.length - 1].prompt === '') {
        return;
      }

      setPendingScheduleIndex(index);
      const response = await createTaskScheduleMutation.mutateAsync({
        task_id: taskId || '',
        prompt: schedulePrompt,
      });

      setPendingScheduleIndex(-1);
      const newSchedules = [...schedules];
      newSchedules[index] = response.data;
      setSchedules(newSchedules);
    },
    [createTaskScheduleMutation, schedules, taskId],
  );

  const deleteTaskScheduleMutation = useDeleteTaskScheduleMutation();

  const handleDeleteTaskSchedule = useCallback(
    async (index: number) => {
      const scheduleId = schedules[index].id;
      if (scheduleId) {
        await deleteTaskScheduleMutation.mutateAsync({task_id: schedules[index].task_id, schedule_id: scheduleId});
      }
      const newSchedules = [...schedules];
      newSchedules.splice(index, 1);
      setSchedules(newSchedules);
    },
    [schedules, deleteTaskScheduleMutation],
  );

  return (
    <div>
      <Label htmlFor='task-schedules'>{t('create-edit-task.fields.schedules.label')}</Label>
      {!taskId && <Alert variant='info'>{t('create-edit-task.fields.schedules.disclaimer')}</Alert>}
      {schedules.map((schedule, index) => (
        <div key={index} className='flex items-center gap-2'>
          <span>{index + 1}</span>

          <Textarea
            id={`task-schedule-${index}`}
            placeholder={t('create-edit-task.fields.schedules.placeholder')}
            value={schedule.schedule?.crontab_description || schedule.prompt}
            disabled={schedule.schedule?.crontab_description !== undefined}
            onValueChange={e => {
              const newSchedules = [...schedules];
              newSchedules[index].prompt = e.target.value;
              setSchedules(newSchedules);
            }}
          />
          <Button
            type='button'
            variant='primary'
            size='sm'
            textSize='base'
            className='justify-center'
            onClick={() => handleAddTaskSchedule(schedule.prompt, index)}
            disabled={
              schedule.prompt === '' ||
              (createTaskScheduleMutation.isPending && pendingScheduleIndex === index) ||
              schedule.schedule?.crontab_description !== undefined
            }
          >
            {createTaskScheduleMutation.isPending && pendingScheduleIndex === index ? (
              <ScoutSpinner />
            ) : (
              <CheckmarkIcon className='stroke-accent-inverse size-6' />
            )}
          </Button>

          <Button
            type='button'
            variant='cancel'
            size='sm'
            textSize='base'
            onClick={() => handleDeleteTaskSchedule(index)}
          >
            <TrashIcon className='stroke-accent-inverse size-6' />
          </Button>
        </div>
      ))}

      {taskId && (
        <Button
          type='button'
          variant='secondary'
          size='sm'
          textSize='base'
          className='mt-2'
          onClick={() => {
            setSchedules([...schedules, {id: '', task_id: '', prompt: '', schedule: undefined}]);
          }}
        >
          {t('create-edit-task.actions.add-schedule')}
        </Button>
      )}
    </div>
  );
};

export default CreateEditSchedulesSection;
