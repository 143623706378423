import ConversationListMoreMenu from '#/components/ConversationListMoreMenu';
import ConversationMoreMenu from '#/components/ConversationMoreMenu';
import AssistantAvatar from '#/components/assistants/AssistantAvatar.tsx';
import {useUpdateConversationMutation} from '#/hooks/query/conversations';
import {useAssistants} from '#/hooks/use-assistants.tsx';
import {useConfig} from '#/hooks/use-config.tsx';
import {useConversationRefs} from '#/hooks/use-conversation-refs.tsx';
import {useConversations} from '#/hooks/use-conversations';
import {useToasts} from '#/hooks/use-toasts';
import {useConversationsListEdit} from '#/hooks/useConversationsListEdit';
import {SidebarBoxNavLink} from '#/library/sidebar/SidebarBoxNavLink';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import {CheckIcon, Cross1Icon, SymbolIcon} from '@radix-ui/react-icons';
import React, {ComponentProps, FunctionComponent, useMemo, useRef, useState} from 'react';
import {Flipped} from 'react-flip-toolkit';
import {useTranslation} from 'react-i18next';
import {useClickAway} from 'react-use';

const MAX_CONVERSATION_TITLE_LENGTH = 100;

export const ConversationsList: FunctionComponent<{
  title: string;
  conversations: ConversationSummaryResponse[];
}> = ({title, conversations}) => {
  const {registerRef} = useConversationRefs();
  const [openMenuKey, setOpenMenuKey] = useState('');

  const onOpenChange = (isOpen: boolean) => {
    setOpenMenuKey(isOpen ? title : '');
  };

  return (
    <div className='pb-2 bg-surface-02 rounded-xl text-primary overflow-hidden group/conversation-list'>
      <div className='relative flex justify-between items-center px-4 pt-4 pb-1'>
        <h2 className='text-sm text-secondary'>{title}</h2>

        <ConversationListMoreMenu
          isOpen={openMenuKey === title}
          conversations={conversations}
          onOpenChange={onOpenChange}
        />
      </div>

      {conversations.map(conversation => (
        <div key={conversation.id} ref={el => registerRef(conversation.id, el)}>
          <ConversationsListItem conversation={conversation} />
        </div>
      ))}
    </div>
  );
};

type ConversationsListItemProps = {
  conversation: ConversationSummaryResponse;
};
const ConversationsListItem: FunctionComponent<ConversationsListItemProps> = ({conversation}) => {
  const {isEditing} = useConversationsListEdit();

  const editing = isEditing === conversation.id;
  const updateConversationMutation = useUpdateConversationMutation();

  if (editing) {
    return (
      <ConversationsListItemEdit conversation={conversation} updateConversationMutation={updateConversationMutation} />
    );
  }

  return (
    <ConversationBoxListItemDisplay
      conversation={conversation}
      updateConversationMutation={updateConversationMutation}
    />
  );
};

const ConversationsListItemEdit: FunctionComponent<
  ConversationsListItemProps & {
    updateConversationMutation: ReturnType<typeof useUpdateConversationMutation>;
  }
> = ({conversation, updateConversationMutation}) => {
  const {t} = useTranslation();
  const {setIsEditing, editedTitle, setEditedTitle} = useConversationsListEdit();
  const {addToast} = useToasts();

  const handleRename = (conversation: ConversationSummaryResponse) => {
    if (editedTitle.length === 0 || editedTitle.length > MAX_CONVERSATION_TITLE_LENGTH) {
      addToast(
        t('sidebar.conversations.toasts.rename-failure-character-limit', {
          max_characters: MAX_CONVERSATION_TITLE_LENGTH,
        }),
        'error',
      );
    } else if (editedTitle !== conversation.title) {
      updateConversationMutation.mutate({conversationId: conversation.id, editedConversation: {title: editedTitle}});
    }

    setIsEditing(null);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(event.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleRename(conversation);
    } else if (e.key === 'Escape') {
      setIsEditing(null);
    }
  };

  const onFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const lengthOfInput = event.currentTarget.value.length;
    return event.currentTarget.setSelectionRange(lengthOfInput, lengthOfInput);
  };

  const onCancelRename = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsEditing(null);
  };

  const onSaveRename = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleRename(conversation);
  };

  const divRef = useRef<HTMLDivElement>(null);
  useClickAway(divRef, () => {
    setIsEditing(null);
  });

  return (
    <div ref={divRef} className='gap-2 items-center rounded-lg bg-surface-02 cursor-pointer mx-0 mr-1.5 py-2.5'>
      <input
        autoFocus
        type='text'
        className='w-full bg-transparent caret-accent outline-none px-4'
        value={editedTitle}
        minLength={1}
        maxLength={MAX_CONVERSATION_TITLE_LENGTH}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
      <div className='w-full gap-x-2 grid grid-cols-2 grid-rows-1 mt-2 px-3'>
        <ConversationBoxListItemEditConfirmButton
          IconComponent={Cross1Icon}
          title={t('sidebar.conversations.actions.cancelRename')}
          onClick={onCancelRename}
        />

        <ConversationBoxListItemEditConfirmButton
          IconComponent={CheckIcon}
          title={t('sidebar.conversations.actions.saveRename')}
          onClick={onSaveRename}
        />
      </div>
    </div>
  );
};

const ConversationBoxListItemEditConfirmButton: FunctionComponent<
  ComponentProps<'button'> & {
    IconComponent: typeof CheckIcon;
    title: string;
    isLoading?: boolean;
  }
> = ({onClick, IconComponent, title, isLoading, ...rest}) => (
  <button
    {...rest}
    className='text-sm w-full bg-surface-03 text-primary flex justify-center items-center gap-1 px-2 py-1 rounded-lg hover:opacity-50'
    onClick={onClick}
  >
    <span>{title}</span>
    {isLoading ? (
      <SymbolIcon className='size-4 text-primary animate-spin' />
    ) : (
      <IconComponent className='size-4 text-primary' />
    )}
  </button>
);

const ConversationBoxListItemDisplay: FunctionComponent<
  ConversationsListItemProps & {
    updateConversationMutation: ReturnType<typeof useUpdateConversationMutation>;
  }
> = ({conversation, updateConversationMutation}) => {
  const {activeConversationId} = useConversations();
  const {openMenuUuid, setOpenMenuUuid, setIsEditing, editedTitle, setEditedTitle} = useConversationsListEdit();

  const isActive = conversation.id === activeConversationId;
  const dropdownOpen = openMenuUuid === conversation.id;

  const onOpenChange = (isOpen: boolean) => {
    setOpenMenuUuid(isOpen ? conversation.id : null);
  };

  const {config} = useConfig();

  return (
    <Flipped flipId={conversation.id}>
      <SidebarBoxNavLink
        to={`/chat/${conversation.id}`}
        isActive={isActive}
        dropdownOpen={dropdownOpen}
        title={conversation.title}
      >
        {{
          image: config.features.assistants ? (
            <ConversationBoxListItemAssistantAvatar assistantId={conversation.assistant_id} />
          ) : null,
          title: updateConversationMutation.isPending ? editedTitle : conversation.title,
          button: updateConversationMutation.isPending ? (
            <div className='p-1 md:p-0 flex justify-center items-center md:absolute md:top-0 md:right-0 md:h-full pointer-events-none'>
              <div className='hidden md:block h-full w-4 bg-gradient-to-l from-surface-01' />
              <div className='md:pl-3 md:pr-5 md:inline-flex md:items-center md:h-full md:bg-surface-01 group/button'>
                <SymbolIcon className='size-4 text-primary animate-spin' />
              </div>
            </div>
          ) : (
            <ConversationMoreMenu
              uuid={conversation.id}
              isOpen={dropdownOpen}
              onOpenChange={onOpenChange}
              setIsEditing={setIsEditing}
              setEditedTitle={setEditedTitle}
              currentTitle={conversation.title}
            />
          ),
        }}
      </SidebarBoxNavLink>
    </Flipped>
  );
};

const ConversationBoxListItemAssistantAvatar: FunctionComponent<{assistantId: string | null}> = ({assistantId}) => {
  const {assistants} = useAssistants();
  const assistant = useMemo(
    () => assistants.find(assistant => assistant.id === assistantId),
    [assistantId, assistants],
  );

  if (!assistant) {
    return null;
  }

  return (
    <div className='flex items-center justify-center'>
      <AssistantAvatar
        src={assistant?.avatar_url}
        containerClassName='size-6'
        className='rounded-full'
        assistantName={assistant?.name}
      />
    </div>
  );
};
