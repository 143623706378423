import AssistantAvatar from '#/components/assistants/AssistantAvatar.tsx';
import {
  useAssistantAvatarAllowedContentTypesQuery,
  useGenerateAssistantAvatarMutation,
  useUploadAssistantAvatarMutation,
} from '#/hooks/query/assistants.tsx';
import {useConfig} from '#/hooks/use-config';
import {Button} from '#/library/button/Button';
import {UploadAssistantAvatarResponse} from '#/repositories/assistants-api/requests/upload-assistant-avatar';
import {AxiosResponse} from 'axios';
import React, {FunctionComponent, useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as SparkIcon} from 'src/resources/spark-icon.svg';
import {ReactComponent as UploadIcon} from 'src/resources/upload-icon.svg';

const AssistantAvatarMenu: FunctionComponent<{
  assistantId: string | undefined;
  assistantName: string;
  description: string;
  avatarUrl?: string;
  setAvatarUrl: (url: string) => void;
}> = props => {
  const {t} = useTranslation();
  const {assistantId, assistantName, avatarUrl, description, setAvatarUrl} = props;
  const {config} = useConfig();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const avatarAllowedContentTypesQuery = useAssistantAvatarAllowedContentTypesQuery();
  const allowedContentTypes = (avatarAllowedContentTypesQuery.data?.data.allowed_content_types || []).join(',');

  const handleFileUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    handleUploadAvatarClick(file);
  };

  const setAvatarUrlOnResponse = useCallback(
    (response: AxiosResponse<UploadAssistantAvatarResponse>) => {
      setAvatarUrl(response.data.protected_url);
    },
    [setAvatarUrl],
  );

  const generateAvatarMutation = useGenerateAssistantAvatarMutation(setAvatarUrlOnResponse);
  const handleGenerateAvatarClick = useCallback(() => {
    generateAvatarMutation.mutate({
      name: assistantName,
      description,
      assistant_id: assistantId || '',
    });
  }, [description, generateAvatarMutation, assistantName, assistantId]);

  const uploadAvatarMutation = useUploadAssistantAvatarMutation(setAvatarUrlOnResponse);
  const handleUploadAvatarClick = useCallback(
    (file: File) => {
      uploadAvatarMutation.mutate({file, assistant_id: assistantId || ''});
    },
    [uploadAvatarMutation, assistantId],
  );

  return (
    <div className='flex flex-col xl:flex-row items-center gap-4 xl:gap-2'>
      <AssistantAvatar
        src={avatarUrl}
        containerClassName='aspect-square size-20'
        className='sm:text-2xl'
        alt={t('conversation.assistant.avatar.alt')}
        assistantName={assistantName}
      />

      <div className='flex gap-2'>
        <Button
          type='button'
          variant='primary'
          size='sm'
          textSize='base'
          className='md:ml-4 text-nowrap'
          isLoading={uploadAvatarMutation.isPending}
          disabled={uploadAvatarMutation.isPending || generateAvatarMutation.isPending}
          onClick={handleFileUploadClick}
        >
          <UploadIcon className='stroke-accent-inverse shrink-0 ' />
          {t('create-edit-assistant.fields.avatar.actions.upload')}
        </Button>

        {config.features.imageGeneration && (
          <Button
            type='button'
            variant='secondary'
            size='sm'
            textSize='base'
            isLoading={generateAvatarMutation.isPending}
            disabled={generateAvatarMutation.isPending || uploadAvatarMutation.isPending}
            onClick={handleGenerateAvatarClick}
          >
            <SparkIcon className='stroke-primary shrink-0' />
            {t('create-edit-assistant.fields.avatar.actions.generate')}
          </Button>
        )}
      </div>

      <input
        ref={fileInputRef}
        onChange={handleFileChange}
        type='file'
        accept={allowedContentTypes}
        className='hidden'
        tabIndex={-1}
      />
    </div>
  );
};

export default AssistantAvatarMenu;
