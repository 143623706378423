import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

const Button = ({disabled = false, className = '', ...props}: ButtonProps) => {
  const classes = `${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`;
  return <button className={classes.trim()} disabled={disabled} {...props} />;
};

export default Button;
