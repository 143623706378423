import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {UploadAssistantAvatarResponse} from '#/repositories/assistants-api/requests/upload-assistant-avatar.ts';
import {AxiosResponse} from 'axios';

export type GenerateAssistantAvatarRequest = {
  assistant_id: string;
  name: string;
  description: string;
};

export const generateAssistantAvatar = (
  request: GenerateAssistantAvatarRequest,
): Promise<AxiosResponse<UploadAssistantAvatarResponse>> => {
  return ScoutAPI.post<GenerateAssistantAvatarRequest, AxiosResponse<UploadAssistantAvatarResponse>>(
    `/assistants/${request.assistant_id}/avatar/generate`,
    request,
  );
};
