import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {castJson} from '#/utils/json-utils.ts';
import {useTranslation} from 'react-i18next';

export interface GoogleSearchArguments {
  url: string;
}

const GetWebpageContentToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  const toolArguments = castJson<GoogleSearchArguments>(props.arguments);

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />
      <span>
        {toolArguments
          ? t('tools.get-webpage-content.in-progress-text-with-url', {url: toolArguments.url})
          : t('tools.get-webpage-content.in-progress-text')}
      </span>
    </div>
  );
};

export default GetWebpageContentToolLoader;
