import {DefaultLoader} from '#/components/tool-loaders/DefaultToolLoader.tsx';
import {ToolStatusLoaderProps} from '#/components/tool-loaders/ToolStatusLoader';
import {castJson} from '#/utils/json-utils';
import {useTranslation} from 'react-i18next';

export interface GetFileTextContentToolLoaderProps {
  filename: string;
}

const GetFileTextContentToolLoader = (props: ToolStatusLoaderProps) => {
  const {t} = useTranslation();

  const toolArguments = castJson<GetFileTextContentToolLoaderProps>(props.arguments);

  return (
    <div className='flex gap-1 items-center'>
      <DefaultLoader />

      {toolArguments ? (
        <span>
          {t('tools.get-file-text-content.in-progress-text-with-filename', {filename: toolArguments.filename})}
        </span>
      ) : (
        <span>{t('tools.get-file-text-content.in-progress-text')}</span>
      )}
    </div>
  );
};
export default GetFileTextContentToolLoader;
