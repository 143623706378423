import {useMemo} from 'react';

export const isProtectedRegex = /^(\/?protected\/)/;

export const useIsProtectedUrl = (url: string | undefined) => {
  return useMemo(() => {
    if (!url) {
      return false;
    }
    return isProtectedRegex.test(url);
  }, [url]);
};
