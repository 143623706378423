import LayoutContentWrapper from '#/components/layout/LayoutContentWrapper.tsx';
import SidebarWrapper from '#/components/layout/SidebarWrapper.tsx';
import EmptySidebar from '#/library/sidebar/EmptySidebar.tsx';
import {FunctionComponent} from 'react';
import {Outlet} from 'react-router-dom';

const EmptySidebarLayout: FunctionComponent = () => {
  return (
    <>
      <SidebarWrapper alwaysCollapsed={true}>
        <EmptySidebar />
      </SidebarWrapper>
      <LayoutContentWrapper>
        <Outlet />
      </LayoutContentWrapper>
    </>
  );
};

export default EmptySidebarLayout;
