import {useConfig} from '#/hooks/use-config';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {QuestionMarkCircledIcon} from '@radix-ui/react-icons';
import React, {memo} from 'react';

const HelpButton = () => {
  const {
    config: {
      application: {supportLinks},
    },
  } = useConfig();

  if (!supportLinks || !supportLinks.length) {
    return null;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className='size-6 shrink-0 rounded-xl flex justify-center items-center hover:opacity-70 transition-opacity'>
          <QuestionMarkCircledIcon className='size-full text-secondary' />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className='min-w-[200px] bg-surface-02 p-2 rounded-md shadow-lg text-sm text-primary z-30'
          sideOffset={5}
          align='end'
          side='top'
        >
          {supportLinks.map((supportLink, index) => (
            <React.Fragment key={supportLink.url}>
              <DropdownMenu.Item className='flex items-center gap-4 rounded-md cursor-pointer hover:bg-surface-03 focus:outline-none'>
                <a href={supportLink.url} target='_blank' className='p-2 w-full' rel='noopener noreferrer'>
                  <div className='flex items-center grow'>
                    <div className='flex flex-col'>{supportLink.title} </div>
                  </div>
                </a>
              </DropdownMenu.Item>
              {index !== supportLinks.length - 1 && <DropdownMenu.Separator className='my-3 mx-2 h-px bg-surface-03' />}
            </React.Fragment>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default memo(HelpButton);
