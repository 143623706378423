import DefaultToolLoader from '#/components/tool-loaders/DefaultToolLoader.tsx';
import ExecuteCapabilityToolLoader from '#/components/tool-loaders/ExecuteCapabilityToolLoader';
import ExecuteCodeToolLoader from '#/components/tool-loaders/ExecuteCodeToolLoader.tsx';
import ExecuteExternalServicesCodeTool from '#/components/tool-loaders/ExecuteExternalServicesCodeTool';
import GenerateImageContentToolLoader from '#/components/tool-loaders/GenerateImageContentToolLoader.tsx';
import GetFileTextContentToolLoader from '#/components/tool-loaders/GetFileTextContentToolLoader';
import GetWebpageContentToolLoader from '#/components/tool-loaders/GetWebpageContentToolLoader.tsx';
import GoogleSearchToolLoader from '#/components/tool-loaders/GoogleSearchToolLoader.tsx';
import GetExternalServicesInstructionsToolLoader from '#/components/tool-loaders/ReadDocumentationTool';
import SearchCapabilitiesToolLoader from '#/components/tool-loaders/SearchCapabilitiesToolLoader';
import SearchContentToolLoader from '#/components/tool-loaders/SearchContentToolLoader.tsx';
import SearchLinksContentToolLoader from '#/components/tool-loaders/SearchLinkContentToolLoader';
import {ReactNode, useMemo} from 'react';

export interface ToolStatusLoaderProps {
  status: string;
  arguments: string;
  toolName: string;
}

interface ToolComponent {
  (props: ToolStatusLoaderProps): ReactNode;
}

const toolComponents: Record<string, ToolComponent> = {
  execute_capability: ExecuteCapabilityToolLoader,
  execute_external_services_code: ExecuteExternalServicesCodeTool,
  execute_python_code: ExecuteCodeToolLoader,
  generate_image: GenerateImageContentToolLoader,
  get_external_service_instructions: GetExternalServicesInstructionsToolLoader,
  get_file_text_content: GetFileTextContentToolLoader,
  get_webpage_content: GetWebpageContentToolLoader,
  google_web_search: GoogleSearchToolLoader,
  search_capabilities: SearchCapabilitiesToolLoader,
  search_files_content: SearchContentToolLoader,
  search_links_content: SearchLinksContentToolLoader,
};

const ToolStatusLoader = (props: ToolStatusLoaderProps) => {
  const ToolLoader = useMemo(() => {
    const Component = toolComponents[props.toolName] || DefaultToolLoader;
    return <Component status={props.status} arguments={props.arguments} toolName={props.toolName} />;
  }, [props.toolName, props.status, props.arguments]);

  return ToolLoader;
};

export default ToolStatusLoader;
