import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {AxiosResponse} from 'axios';

export type ValidateAdminConfigRequest = {
  config: string;
};

export type ValidateAdminConfigResponse = {
  message: string;
};

export const validateAdminConfig = (
  request: ValidateAdminConfigRequest,
): Promise<AxiosResponse<ValidateAdminConfigResponse>> => {
  return ScoutAPI.post<ValidateAdminConfigRequest, AxiosResponse<ValidateAdminConfigResponse>>(
    '/config/validate',
    request,
  );
};
