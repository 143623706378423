export enum LocalStorageKeys {
  OKTA_NONCE = 'scout_okta_nonce',
  SELECTED_MODEL_ID = 'scout_selected_model_name',
  CONVERSATION_MAX_WIDTH = 'scout_conversation_controls_max_width',
  SIDEBAR_COLLAPSED = 'scout_sidebar_collapsed',
  NUMBER_OF_ASSISTANTS_IN_SIDEBAR = 'scout_number_of_assistants_in_sidebar',
  USER_AGREEMENTS_ACCEPTED = 'scout_user_agreements_accepted',
  USE_CASES_VIEW_ENABLED = 'scout_use_cases_view_enabled',
}

const environment = {
  baseApiUrl: import.meta.env['VITE_API_BASE_URL'] || '',
} as const;

export default environment;
