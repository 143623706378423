import {ConversationMessage} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {CheckIcon, CopyIcon} from '@radix-ui/react-icons';
import {FunctionComponent, memo, useCallback, useState} from 'react';
import {twMerge} from 'tailwind-merge';

export interface GenericCopyButtonProps {
  content: string | ConversationMessage;
  className?: string;
}

const GenericCopyButton: FunctionComponent<GenericCopyButtonProps> = ({content, className = ''}) => {
  const [copied, setCopied] = useState(false);

  const onCopyClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const getContentToCopy = (): string => {
        if (typeof content === 'string') {
          return content;
        } else if ('content' in content && typeof content.content === 'string') {
          return content.content;
        }
        return '';
      };

      const textToCopy = getContentToCopy();
      await navigator.clipboard.writeText(textToCopy);

      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
    [content],
  );

  const buttonClassNames = twMerge(
    className,
    'flex items-center justify-center size-6 rounded-lg stroke-secondary hover:text-primary hover:stroke-primary transition-colors cursor-pointer',
  );

  return (
    <button className={buttonClassNames} onClick={onCopyClick}>
      {copied ? <CheckIcon /> : <CopyIcon />}
    </button>
  );
};

export default memo(GenericCopyButton);
