import {useConfig} from '#/hooks/use-config.tsx';
import {LocalStorageKeys} from '#/repositories/environment.ts';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocalStorage} from 'react-use';

export const useUserAgreement = () => {
  const {i18n} = useTranslation();
  const [userAgreementsAccepted, setUserAgreementsAccepted] = useLocalStorage(
    LocalStorageKeys.USER_AGREEMENTS_ACCEPTED,
    false,
  );
  const {config} = useConfig();

  const userAgreementText: string = useMemo(() => {
    const language = i18n.languages.find(lang => config.application.userAgreement?.text.hasOwnProperty(lang));
    if (!language) {
      return '';
    }

    let result = config.application.userAgreement?.text[language] || '';
    const links = config.application.userAgreement?.links;

    for (const key in links) {
      if (!links.hasOwnProperty(key)) {
        continue;
      }

      const link = links[key];
      if (!link.hasOwnProperty(language)) {
        continue;
      }
      const localedLink = link[language];

      const placeholder = `{${key}}`;
      const replacement = `<a target="_blank" rel="noreferrer" href="${localedLink.url}">${localedLink.title}</a>`;
      result = result.replace(new RegExp(placeholder, 'g'), replacement);
    }

    return result;
  }, [config.application.userAgreement?.links, config.application.userAgreement?.text, i18n.languages]);

  return {
    userAgreementsAccepted,
    setUserAgreementsAccepted,
    userAgreementText,
  };
};
