import React, {createContext, ReactNode, useContext} from 'react';
import {toast} from 'sonner';

const TOAST_DURATION = 5000;

export type ToastAppearance = 'success' | 'error' | 'warning' | 'info';

interface ToastsContextType {
  addToast: (message: string, appearance: ToastAppearance) => void;
}

const ToastsContext = createContext<ToastsContextType | undefined>(undefined);

export const useToasts = () => {
  const context = useContext(ToastsContext);

  if (context === undefined) {
    throw new Error('useToasts must be used within a ConversationsProvider');
  }
  return context;
};

const addToast = (message: string, appearance: ToastAppearance) => {
  switch (appearance) {
    case 'success':
      toast.success(message, {duration: TOAST_DURATION});
      break;
    case 'error':
      toast.error(message, {duration: TOAST_DURATION});
      break;
    case 'warning':
      toast.warning(message, {duration: TOAST_DURATION});
      break;
    case 'info':
      toast.info(message, {duration: TOAST_DURATION});
      break;
  }
};

export const ToastsProvider: React.FC<{children: ReactNode}> = ({children}) => {
  return <ToastsContext.Provider value={{addToast}}>{children}</ToastsContext.Provider>;
};
