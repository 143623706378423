import {useAuthenticatedQuery} from '#/hooks/query/base.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import {useToasts} from '#/hooks/use-toasts.tsx';
import {createConversation} from '#/repositories/assistants-api/requests/create-conversation.ts';
import {deleteConversation} from '#/repositories/assistants-api/requests/delete-conversation.ts';
import {deleteConversations} from '#/repositories/assistants-api/requests/delete-conversations';
import {fetchConversation} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {
  castToConversationSummaryResponse,
  ConversationSummaryResponse,
  fetchConversations,
} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {
  updateConversationMessage,
  UpdateConversationMessageRequest,
} from '#/repositories/assistants-api/requests/update-conversation-message.ts';
import {
  updateConversation,
  UpdateConversationRequest,
} from '#/repositories/assistants-api/requests/update-conversation.ts';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export const useConversationsQuery = () => {
  return useAuthenticatedQuery({
    queryKey: ['conversations'],
    queryFn: async () => {
      const response = await fetchConversations();
      return response.data;
    },
  });
};
export const useConversationsSetQueryData = () => {
  const {user} = useAuth();
  const queryClient = useQueryClient();

  return useCallback(
    (modifyData: Parameters<typeof queryClient.setQueryData<ReturnType<typeof useConversationsQuery>['data']>>[1]) => {
      queryClient.setQueryData<ReturnType<typeof useConversationsQuery>['data']>(
        [user?.id, 'conversations'],
        modifyData,
      );
    },
    [queryClient, user?.id],
  );
};

type UseConversationQueryParams = {
  conversationId?: string;
};
export const useConversationQuery = ({conversationId}: UseConversationQueryParams) =>
  useAuthenticatedQuery({
    queryKey: ['conversation', conversationId],
    queryFn: async () => {
      const response = await fetchConversation(conversationId || '');
      return response.data;
    },
    enabled: !!conversationId,
    refetchOnWindowFocus: false,
  });

export const useCreateConversationMutation = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useMutation({
    mutationKey: ['create-conversation'],
    mutationFn: createConversation,
    onSuccess: ({data}) => {
      setConversationsQueryData((oldData = []) => {
        return [castToConversationSummaryResponse(data), ...oldData];
      });
      navigate(`/chat/${data.id}`, {state: {shouldStartStream: true}, replace: true});
    },
    onError: error => {
      addToast(t('errors.create-conversation'), 'error');
    },
  });
};

export const useUpdateConversationMutation = () => {
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['update-conversation'],
    mutationFn: async (data: {conversationId: string; editedConversation: UpdateConversationRequest}) => {
      return updateConversation(data.conversationId, data.editedConversation);
    },
    onSuccess: ({data: updatedConversation}) => {
      setConversationsQueryData((oldData = []) => {
        return prependUpdatedConversation(oldData, updatedConversation);
      });
    },
    onError: error => {
      addToast(errorMessageForError(error, 'sidebar.conversations.toasts.update-failure'), 'error');
    },
  });
};

export const useUpdateConversationMessageMutation = () => {
  const {addToast} = useToasts();
  const {errorMessageForError} = useErrorMessage();

  return useMutation({
    mutationKey: ['edit-conversation-message'],
    mutationFn: async (data: {conversationId: string; request: UpdateConversationMessageRequest}) =>
      updateConversationMessage(data.conversationId, data.request),
    onError: error => {
      addToast(errorMessageForError(error, 'conversation.toasts.edit-message-failure'), 'error');
    },
  });
};

export const useDeleteConversationMutation = () => {
  const {t} = useTranslation();
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useMutation({
    mutationKey: ['delete-conversation'],
    mutationFn: deleteConversation,
    onSuccess: ({data: deletedConversationId}) => {
      setConversationsQueryData((oldData = []) => {
        return oldData.filter(conversation => conversation.id !== deletedConversationId);
      });
    },
    onError: () => {
      addToast(t('errors.delete-conversation'), 'error');
    },
  });
};

export const useDeleteConversationsMutation = () => {
  const {t} = useTranslation();
  const {addToast} = useToasts();
  const setConversationsQueryData = useConversationsSetQueryData();

  return useMutation({
    mutationKey: ['delete-conversations'],
    mutationFn: deleteConversations,
    onSuccess: ({data: deletedConversationIds}) => {
      setConversationsQueryData((oldData = []) => {
        return oldData.filter(conversation => !deletedConversationIds.includes(conversation.id));
      });
    },
    onError: () => {
      addToast(t('errors.delete-conversations'), 'error');
    },
  });
};

export const prependUpdatedConversation = (
  oldData: ConversationSummaryResponse[] = [],
  updatedConversation: ConversationSummaryResponse,
) => {
  const filteredData = oldData.filter(conversation => conversation.id !== updatedConversation.id);

  return [updatedConversation, ...filteredData];
};
