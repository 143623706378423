import {ToolUsedItemProps, ToolUsedItemWrapper} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {castJson} from '#/utils/json-utils.ts';
import {MagnifyingGlassIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface GoogleSearchToolArguments {
  query: string;
}

const GoogleSearchToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<GoogleSearchToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <MagnifyingGlassIcon className='inline-block size-4 text-accent shrink-0' />
        <span>{t('conversation.tool-used.tools.google-search', {query: toolArguments?.query})}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default GoogleSearchToolUsedItem;
