import {KeyPrefix, ParseKeysByKeyPrefix} from 'i18next';

interface UseCase {
  id: string;
}

export interface ConversationUseCase extends UseCase {
  id: ParseKeysByKeyPrefix<KeyPrefix<'translation'>, 'conversation.use-cases.titles'>;
}

export interface AssistantUseCase extends UseCase {
  assistantId: string;
  instructions: string;
}

export interface ConfigUseCase extends UseCase {
  id: string;
  fr: string;
  en: string;
}

export type AnyUseCase = ConversationUseCase | AssistantUseCase | ConfigUseCase;

export const genericUseCases: ConversationUseCase[] = [];

export const googleIntegrationUseCases: ConversationUseCase[] = [
];

export const slackIntegrationUseCases: ConversationUseCase[] = [
];

export const imageGenerationUseCases: ConversationUseCase[] = [
];
