import AppContentWrapper from '#/AppContentWrapper';
import {AuthProvider} from '#/hooks/use-auth';
import {ConfigurationProvider} from '#/hooks/use-config';
import {ThemeProvider} from '#/hooks/use-theme';
import {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';

export const APP_NAME = 'Scout';

const App = () => {
  return (
    <BrowserRouter>
      <Suspense>
        <ConfigurationProvider>
          <ThemeProvider>
            <AuthProvider>
              <AppContentWrapper />
            </AuthProvider>
          </ThemeProvider>
        </ConfigurationProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
