import {ChatPageState} from '#/components/chat-page/ChatPage.tsx';
import {ConversationMessage, ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {UseQueryResult} from '@tanstack/react-query';
import {useEffect, useRef} from 'react';

export const useStartStreamingOnNewConversation = (
  messages: ConversationMessage[],
  handleStreamChatCompletion: (
    newMessages: ConversationMessage[] | null,
    newConversation: ConversationMessage[],
  ) => void,
  state: ChatPageState,
  conversationQuery: UseQueryResult<ConversationResponse, Error>,
) => {
  const didStartStream = useRef(false);

  useEffect(() => {
    if (!didStartStream.current && state?.shouldStartStream && messages.length > 0) {
      didStartStream.current = true;
      handleStreamChatCompletion(null, [...messages]);
    }
  }, [state?.shouldStartStream, messages, handleStreamChatCompletion]);

  useEffect(() => {
    didStartStream.current = false;
  }, [conversationQuery.data]);
};
