import {ToolUsedItemProps, ToolUsedItemWrapper} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {castJson} from '#/utils/json-utils.ts';
import {Link1Icon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface SearchLinkContentToolArguments {
  prompt: string;
}
const SearchLinksContentToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<SearchLinkContentToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <Link1Icon className='inline-block size-4 shrink-0 text-accent' />
        <span>{t('conversation.tool-used.tools.search-links-content', {prompt: toolArguments?.prompt})}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default SearchLinksContentToolUsedItem;
