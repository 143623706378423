import {DetailedHTMLProps, FunctionComponent, InputHTMLAttributes} from 'react';
import {twMerge} from 'tailwind-merge';

export const Input: FunctionComponent<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  className,
  ...props
}) => {
  return (
    <input
      className={twMerge(
        'appearance-none block bg-surface-01 border-2 border-stroke-main rounded-xl py-3 px-4 leading-tight focus:outline-none focus:bg-surface-02',
        className,
      )}
      {...props}
    />
  );
};
