import {ModelVisibilityType, SharedUser} from '#/repositories/assistants-api/requests/visibility.ts';

export const parseSharedUsersToEmails = (visibility: ModelVisibilityType, sharedUsers: readonly SharedUser[]) => {
  if (visibility === 'shared') {
    return parseUsersToEmails(sharedUsers);
  }
  return undefined;
};

export const parseUsersToEmails = (users: readonly SharedUser[]) => {
  return users.map(user => user.email);
};
