import {ChatModel} from '#/repositories/config';
import {useMemo} from 'react';

export const useSelectConfig = (
  selectedModelId: string | undefined,
  setSelectedModelId: (value: string | undefined) => void,
  chatModels: ChatModel[],
) => {
  return useMemo(() => {
    return {
      name: 'models',
      value: selectedModelId ?? '',
      onChange: (newValue: string) => setSelectedModelId(newValue),
      options: chatModels.map(model => ({
        id: model.id,
        value: model.displayName,
        description: model.description,
      })),
    };
  }, [chatModels, selectedModelId, setSelectedModelId]);
};
