import {FunctionComponent, HTMLProps} from 'react';

export type PageContentHeaderProps = Pick<HTMLProps<HTMLDivElement>, 'children'> & {
  title?: string;
  subtitle?: string;
  variant?: 'title' | 'subtitle';
  maxWidth?: string;
};

export const PageContentHeader: FunctionComponent<PageContentHeaderProps> = ({
  children,
  variant = 'title',
  title,
  subtitle,
  maxWidth,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`w-full gap-0 sticky top-0 left-0 bg-transparent z-10 mx-auto hidden md:block ${!maxWidth && 'max-w-page-content'}`}
      style={{maxWidth}}
    >
      {variant === 'title' ? (
        <div className='flex items-center bg-surface-01 pt-20 pb-2 justify-between px-4'>
          <div className='justify-start'>
            {title && <HeaderTitle title={title} />}
            {subtitle && <HeaderSubtitle subtitle={subtitle} />}
          </div>
          {children}
        </div>
      ) : (
        <div className='flex justify-start items-center gap-2 h-20 bg-surface-01 pt-5 pb-2'>
          {children}
          {title && (
            <h3 className={`grow text-primary font-semibold text-left truncate ${children ? 'px-3' : 'text-xl'}`}>
              {title}
            </h3>
          )}
        </div>
      )}
      <div className='block w-full h-8 bg-gradient-to-b from-surface-01' />
    </div>
  );
};

export const HeaderTitle: FunctionComponent<{title: string}> = ({title}) => {
  return <h3 className='text-primary md:font-semibold md:text-header-title font-bold text-2xl'>{title}</h3>;
};

export const HeaderSubtitle: FunctionComponent<{subtitle: string}> = ({subtitle}) => {
  return (
    <h4 className='text-secondary opacity-70 md:font-semibold md:text-header-title font-normal text-lg'>{subtitle}</h4>
  );
};
