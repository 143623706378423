import {useUpdateConversationMutation} from '#/hooks/query/conversations.tsx';
import {ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {UseQueryResult} from '@tanstack/react-query';
import {SetStateAction, useCallback} from 'react';

const useSetSelectedModelId = (
  conversationId: string | undefined,
  conversationQuery: UseQueryResult<ConversationResponse, Error>,
  setDefaultModelId: (value: SetStateAction<string | undefined>) => void,
) => {
  const updateConversationMutation = useUpdateConversationMutation();

  return useCallback(
    async (selectedModelId: string | undefined) => {
      setDefaultModelId(selectedModelId);

      if (conversationId) {
        await updateConversationMutation.mutateAsync({
          conversationId: conversationId,
          editedConversation: {model: selectedModelId},
        });
        conversationQuery.refetch();
      }
    },
    [conversationId, conversationQuery, setDefaultModelId, updateConversationMutation],
  );
};

export default useSetSelectedModelId;
