import {useConversationRefs} from '#/hooks/use-conversation-refs.tsx';
import {useConversations} from '#/hooks/use-conversations.tsx';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations.ts';
import {useEffect, useRef} from 'react';

const useScrollToConversationOnConversationsChange = (conversations: ConversationSummaryResponse[]) => {
  const {activeConversationId} = useConversations();

  const {scrollToConversation} = useConversationRefs();

  const prevConversationsRef = useRef<ConversationSummaryResponse[] | null>(null);

  useEffect(() => {
    if (prevConversationsRef.current === conversations) {
      return;
    }

    const timer = setTimeout(() => {
      prevConversationsRef.current = conversations;

      if (!activeConversationId) {
        return;
      }
      scrollToConversation(activeConversationId);
    }, 500);

    return () => clearTimeout(timer);
  }, [conversations, activeConversationId, scrollToConversation]);
};

export default useScrollToConversationOnConversationsChange;
