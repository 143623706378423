import environment from '#/repositories/environment';
import {getAccessToken, getRefreshToken, removeAllTokens, setAccessToken} from '#/repositories/tokens_repository';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

const ScoutAPI = axios.create({
  baseURL: `${environment.baseApiUrl}/api`,
  withCredentials: true,
});

const maxRetryAttempts = 10;
const retryDelay = 500;
const baseDelay = 5000;
const delayMultiplier = 1.5;
const statusCodesToRetry = [429];

const anonymousEndpoints = ['/auth/login/', '/auth/refresh/'];

ScoutAPI.interceptors.request.use(request => {
  if (request.url === '/auth/login/') {
    return request;
  }

  const cancelTokenSource = axios.CancelToken.source();

  try {
    const access_token = getAccessToken();
    request.headers.Authorization = `Bearer ${access_token}`;
  } catch (error) {
    removeAllTokens();
    cancelTokenSource.cancel('Request canceled due to invalid login credentials');
  }
  request.cancelToken = cancelTokenSource.token;

  return request;
});

interface AxiosConfigWithRetry extends AxiosRequestConfig {
  retryCount?: number;
}

ScoutAPI.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (!error.config || !error.response || !statusCodesToRetry.includes(error.response.status)) {
      return Promise.reject(error);
    }

    const configWithRetry: AxiosConfigWithRetry = error.config;

    configWithRetry.retryCount = configWithRetry.retryCount || 0;

    if (configWithRetry.retryCount >= maxRetryAttempts) {
      return Promise.reject(error);
    }

    configWithRetry.retryCount += 1;

    const delay = baseDelay + retryDelay * Math.pow(delayMultiplier, configWithRetry.retryCount - 1);
    await new Promise<void>(resolve => setTimeout(() => resolve(), delay));
    return ScoutAPI(configWithRetry);
  },
);

ScoutAPI.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (
      axios.isAxiosError(error) &&
      error.response !== undefined &&
      error.response.status === 401 &&
      error.config?.url !== undefined &&
      !anonymousEndpoints.includes(error.config.url)
    ) {
      try {
        const newAccessToken = await handleRefreshToken();
        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axios(error.config);
      } catch (refreshError) {
        removeAllTokens();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export default ScoutAPI;

export const handleRefreshToken = async () => {
  try {
    const refreshToken = getRefreshToken();

    const response = await axios.post(
      `${ScoutAPI.defaults.baseURL}/auth/refresh/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    );

    const {access_token} = response.data;

    setAccessToken(access_token);

    return access_token;
  } catch (error) {
    throw error;
  }
};

export const getApiToken = async () => {
  try {
    const accessToken = getAccessToken();

    const response = await axios.post(
      `${ScoutAPI.defaults.baseURL}/auth/create-api-token/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
