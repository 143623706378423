import {AxiosError} from 'axios';
import {ParseKeys} from 'node_modules/i18next';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export const useErrorMessage = () => {
  const {t} = useTranslation();

  const convertErrorMessageToUserMessage = useCallback(
    (errorString: string, defaultErrorMessageKey: ParseKeys | null = 'errors.unexpected') => {
      if (errorString.indexOf('context_length_exceeded') > 0) {
        return t('errors.context-length-exceeded');
      }
      return defaultErrorMessageKey ? t(defaultErrorMessageKey) : errorString;
    },
    [t],
  );

  const errorMessageForError = useCallback(
    (error: unknown | AxiosError, defaultErrorMessageKey: ParseKeys | null = 'errors.unexpected') => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          return t('errors.unauthorized');
        } else if (error.response?.data?.error) {
          return error.response.data.error;
        } else return convertErrorMessageToUserMessage(error.response?.data?.error, defaultErrorMessageKey);
      }
      return t(defaultErrorMessageKey ?? 'errors.unexpected');
    },
    [convertErrorMessageToUserMessage, t],
  );

  return {convertErrorMessageToUserMessage, errorMessageForError};
};
