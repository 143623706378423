import ScoutAPI from '#/repositories/assistants-api/api.ts';
import {ConversationMessage, ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {AxiosResponse} from 'axios';

export type UpdateConversationMessageRequest = {
  message: ConversationMessage;
  user_message_index: number;
};

export const updateConversationMessage = (id: string, message: UpdateConversationMessageRequest) =>
  ScoutAPI.put<UpdateConversationMessageRequest, AxiosResponse<ConversationResponse>>(
    `/conversations/${id}/message`,
    message,
  );
