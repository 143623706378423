import {MobileHeader} from '#/components/MobileHeader.tsx';
import Page from '#/components/Page.tsx';
import {Button} from '#/library/button/Button';
import {PageContentHeader} from '#/library/page-content-header/PageContentHeader.tsx';
import {getApiToken} from '#/repositories/assistants-api/api';
import {CheckIcon, CopyIcon, LockClosedIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';

const APITokensPage: FunctionComponent = () => {
  const {t} = useTranslation();
  const [apiToken, setApiToken] = useState('');
  const [copied, setCopied] = useState(false);

  const handleGenerateToken = async () => {
    const token = await getApiToken();
    setApiToken(token);
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(apiToken).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  return (
    <Page title={t('settings.page-header.title')}>
      <MobileHeader>
        <h2 className='grow font-bold text-center md:text-left'>{t('settings.security.api-tokens.title')}</h2>
      </MobileHeader>

      <div className='size-full flex flex-col items-stretch overflow-y-auto'>
        <PageContentHeader title={t('settings.security.api-tokens.title')} />

        <div className='max-w-page-content grow w-full flex flex-col mx-auto gap-y-8 p-4 md:pt-0 '>
          <p>{t('settings.security.api-tokens.description')}</p>

          <Button
            variant='primary'
            size='md'
            textSize='sm'
            onClick={handleGenerateToken}
            className='self-start px-4 py-2 bg-accent rounded-xl font-bold'
          >
            <LockClosedIcon width={18} height={18} />
            {t('settings.security.api-tokens.actions.create')}
          </Button>

          {apiToken && (
            <>
              {t('settings.security.api-tokens.disappear-warning')}
              <pre className='p-4 border rounded-xl whitespace-pre-wrap break-words'>{apiToken}</pre>
              <Button
                variant='secondary'
                size='md'
                textSize='sm'
                onClick={handleCopyToken}
                className='w-fit self-end'
                disabled={copied}
              >
                {copied ? <CheckIcon width={18} height={18} /> : <CopyIcon width={18} height={18} />}

                {copied
                  ? t('settings.security.api-tokens.token-copied')
                  : t('settings.security.api-tokens.actions.copy')}
              </Button>
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default APITokensPage;
