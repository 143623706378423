import {useAuthenticatedQuery} from '#/hooks/query/base.tsx';
import {useAuth} from '#/hooks/use-auth.tsx';
import {useToasts} from '#/hooks/use-toasts.tsx';
import {checkAuth} from '#/repositories/assistants-api/requests/check-auth.ts';
import {externalLogout, prepareExternalLogin} from '#/repositories/assistants-api/requests/external-services.ts';
import {fetchUserConnectedServices} from '#/repositories/assistants-api/requests/fetch-user-connected-services.ts';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';

export const useCheckAuthQuery = () =>
  useQuery({
    queryKey: ['check-auth'],
    queryFn: checkAuth,
    retry: false,
  });

export const useConnectedServicesQuery = () => {
  return useAuthenticatedQuery({
    queryKey: ['connected-services'],
    queryFn: fetchUserConnectedServices,
  });
};

export const usePrepareExternalLoginMutation = () => {
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['prepare-external'],
    mutationFn: prepareExternalLogin,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'connected-services']});
    },
    onError: (error: AxiosError) => {
      const errorMessage = (error.response?.data as {error: string})?.error || t('errors.unexpected');
      addToast(errorMessage, 'error');
    },
  });
};

export const useExternalLogoutMutation = () => {
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {user} = useAuth();

  return useMutation({
    mutationKey: ['external-logout'],
    mutationFn: externalLogout,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [user?.id, 'connected-services']});
    },
    onError: (error: AxiosError) => {
      const errorMessage = (error.response?.data as {error: string})?.error || t('errors.unexpected');
      addToast(errorMessage, 'error');
    },
  });
};
