import {useSidebar} from '#/hooks/use-sidebar';
import {ToggleSidebarButton} from '#/library/toggle-sidebar-button/ToggleSidebarButton';
import {FunctionComponent} from 'react';

export const MobileHeader: FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = '',
  ...props
}) => {
  const {isMobileVisible, mobileToggle} = useSidebar();

  return (
    <div
      {...props}
      className={`w-full p-4 shrink-0 flex justify-between items-center md:hidden border-b border-stroke-main ${className}`}
    >
      <ToggleSidebarButton variant={isMobileVisible ? 'close' : 'open'} onClick={mobileToggle} />
      {children}
    </div>
  );
};
