import {TaskPlanExecutionStep} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {useMemo} from 'react';

const useCodeString = (selectedStep?: TaskPlanExecutionStep) => {
  return useMemo(() => {
    if (!selectedStep?.code_execution?.code) {
      return null;
    }

    return {
      markdown: `\`\`\`python\n${selectedStep?.code_execution?.code ?? ''}`,
      raw: selectedStep?.code_execution?.code,
    };
  }, [selectedStep?.code_execution?.code]);
};
export default useCodeString;
