import {APP_NAME} from '#/App.tsx';
import {useEffect} from 'react';

const useDocumentTitle = (title: string, withAppTitle = true) => {
  useEffect(() => {
    document.title = title + (withAppTitle ? ` - ${APP_NAME}` : '');
  }, [title, withAppTitle]);
};

export default useDocumentTitle;
