import {useToasts} from '#/hooks/use-toasts.tsx';
import {fetchChatModels} from '#/repositories/assistants-api/requests/fetch-chat-models.ts';
import {useQuery} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';

export const useChatModelsQuery = () => {
  const {addToast} = useToasts();
  const {t} = useTranslation();

  const {data, error} = useQuery({queryKey: ['chat-models'], queryFn: fetchChatModels});

  if (error) {
    addToast(t('errors.fetch-chat-models'), 'error');
  }

  return data?.data ?? [];
};
