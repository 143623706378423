import {useConfig} from '#/hooks/use-config';
import {Auth0Provider as RealAuth0Provider} from '@auth0/auth0-react';
import {ReactNode} from 'react';

const Auth0Provider = (props: {children: ReactNode}) => {
  const {config} = useConfig();
  const {auth0} = config.integrations.authentication;

  if (!auth0) {
    throw new Error('Auth0 login not configured');
  }

  return (
    <RealAuth0Provider
      clientId={auth0.clientId}
      domain={auth0.baseUrl}
      authorizationParams={{
        redirect_uri: `${window.location.origin}`,
      }}
    >
      {props.children}
    </RealAuth0Provider>
  );
};

export default Auth0Provider;
