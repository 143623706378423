import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {useEffect} from 'react';

const useSetSelectedIndexWhenOverBounds = (
  selectedIndex: number,
  filteredAssistants: AssistantPublicResponse[],
  setSelectedIndex: (value: number) => void,
) => {
  useEffect(() => {
    if (selectedIndex >= filteredAssistants.length) {
      setSelectedIndex(filteredAssistants.length - 1);
    }
  }, [filteredAssistants.length, selectedIndex, setSelectedIndex]);
};

export default useSetSelectedIndexWhenOverBounds;
