import {useAuth} from '#/hooks/use-auth.tsx';
import * as Avatar from '@radix-ui/react-avatar';
import {ComponentProps, FunctionComponent, useMemo} from 'react';

const getUserInitials = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

export interface UserAvatarProps extends Exclude<ComponentProps<typeof Avatar.Root>, 'children'> {
  pictureUrl: string | null;
  firstName: string;
  lastName: string;
}

export const UserAvatar: FunctionComponent<UserAvatarProps> = ({
  pictureUrl,
  firstName,
  lastName,
  className,
  ...props
}) => {
  const initials = useMemo(() => getUserInitials(firstName, lastName), [firstName, lastName]);

  return (
    <Avatar.Root {...props} className={`rounded-full overflow-hidden ${className}`}>
      <Avatar.Image loading='lazy' decoding='async' className='size-full' src={pictureUrl || undefined} />
      <Avatar.Fallback
        className='size-full flex items-center justify-center bg-accent text-accent-inverse font-medium'
        delayMs={pictureUrl ? 500 : 0}
      >
        {initials}
      </Avatar.Fallback>
    </Avatar.Root>
  );
};

export const CurrentUserAvatar: FunctionComponent<
  Omit<UserAvatarProps, 'pictureUrl' | 'firstName' | 'lastName'>
> = props => {
  const {user} = useAuth();

  return (
    <UserAvatar
      {...props}
      pictureUrl={user?.picture_url || null}
      firstName={user?.firstName || ''}
      lastName={user?.lastName || ''}
    />
  );
};
