import {pickAssistantUseCases} from '#/hooks/chat-page/use-cases/pick-assistant-use-cases.ts';
import {
  AnyUseCase,
  AssistantUseCase,
  ConfigUseCase,
  genericUseCases,
  googleIntegrationUseCases,
  imageGenerationUseCases,
  slackIntegrationUseCases,
} from '#/hooks/chat-page/use-cases/use-cases.ts';
import {useAssistants} from '#/hooks/use-assistants.tsx';
import {useConfig} from '#/hooks/use-config.tsx';
import {getRandomItems, shuffleArray} from '#/utils/array-utils.ts';
import {useMemo} from 'react';

const useUseCases = () => {
  const {assistants} = useAssistants();
  const {config} = useConfig();

  const useCases: AnyUseCase[] = useMemo(() => {
    const assistantUseCases = pickAssistantUseCases(assistants);

    if (assistantUseCases.length > 0) {
      return assistantUseCases;
    }

    const _useCases: AnyUseCase[] = [...genericUseCases, ...assistantUseCases];

    if (config.features.externalServices.find(service => service.name === 'google')) {
      _useCases.push(...googleIntegrationUseCases);
    }

    if (config.features.externalServices.find(service => service.name === 'slack')) {
      _useCases.push(...slackIntegrationUseCases);
    }

    if (config.features.imageGeneration) {
      _useCases.push(...getRandomItems(imageGenerationUseCases, 1));
    }

    _useCases.push(...config.features.useCases);

    const shuffledUseCases = shuffleArray(_useCases);

    return shuffledUseCases;
  }, [assistants, config.features]);

  return useCases;
};

export const isAssistantUseCase = (useCase: AnyUseCase): useCase is AssistantUseCase => {
  return (useCase as AssistantUseCase).assistantId !== undefined;
};

export const isConfigUseCase = (useCase: AnyUseCase): useCase is ConfigUseCase => {
  return (useCase as ConfigUseCase).fr !== undefined && (useCase as ConfigUseCase).en !== undefined;
};

export default useUseCases;
