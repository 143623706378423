import {Sender} from '#/components/chat-page/ChatPage.tsx';
import ScoutAPI from '#/repositories/assistants-api/api';
import {AssistantPublicResponse} from '#/repositories/assistants-api/requests/fetch-assistants.ts';
import {AxiosResponse} from 'axios';
import {ReactNode} from 'react';

export interface ToolCall {
  tool_name: string;
  arguments: string;
}
export interface MessageMetadata {
  assistant_id?: string;
  files?: {name: string; content_type: string}[];
  tool_calls?: ToolCall[];
}

export interface ConversationMessage {
  role: Sender;
  content: string | ReactNode;
  metadata?: MessageMetadata | null;
}

export interface ConversationResponse {
  id: string;
  title: string;
  payload: ConversationMessage[];
  updated_at: string;
  assistant?: AssistantPublicResponse;
  model: string | null;
}

export const fetchConversation = (uuid: string): Promise<AxiosResponse<ConversationResponse>> => {
  return ScoutAPI.get<null, AxiosResponse<ConversationResponse>>(`/conversations/${uuid}`);
};
