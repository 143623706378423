import useDocumentTitle from '#/hooks/use-document-title';
import {ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

export const Page: React.FC<{children: ReactNode; title: string; className?: string}> = ({
  children,
  title,
  className,
}) => {
  useDocumentTitle(title);

  return (
    <div
      className={twMerge(
        'size-full flex flex-col text-primary grow overflow-hidden md:border border-stroke-main md:rounded-2xl md:space-y-0 bg-surface-01',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Page;
