import {login, LoginRequest} from '#/repositories/assistants-api/requests/login.ts';
import {getTokenPayload} from '#/repositories/tokens_repository.ts';
import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  picture_url?: string;
  isAdmin?: boolean;
}

interface AuthContextType {
  user: User | null;
  login: (request: LoginRequest) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [user, setUser] = useState<User | null>(null);

  const handleSetUser = useCallback(async () => {
    const payload = await getTokenPayload();
    if (!payload) {
      throw new Error('Invalid token');
    }

    const user: User = {
      id: payload.sub,
      firstName: payload.first_name,
      lastName: payload.last_name,
      picture_url: payload.picture_url,
      isAdmin: payload.is_admin,
    };

    setUser(user);
  }, []);

  useEffect(() => {
    try {
      handleSetUser();
    } catch {}
  }, [handleSetUser]);

  const handleLogin = useCallback(
    async (request: LoginRequest) => {
      await login(request);
      handleSetUser();
    },
    [handleSetUser],
  );

  const value = {
    user,
    login: handleLogin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
