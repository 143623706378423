import {useErrorMessage} from '#/hooks/use-error-message.tsx';
import {useToasts} from '#/hooks/use-toasts.tsx';
import {ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {UseQueryResult} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export const useRedirectOnInvalidConversation = (conversationQuery: UseQueryResult<ConversationResponse, Error>) => {
  const navigate = useNavigate();
  const {addToast} = useToasts();
  const {t} = useTranslation();
  const {errorMessageForError} = useErrorMessage();

  useEffect(() => {
    if (!!conversationQuery.error) {
      navigate('/chat', {replace: true});

      addToast(errorMessageForError(conversationQuery.error), 'error');
      return;
    }
  }, [addToast, conversationQuery.error, errorMessageForError, navigate, t]);
};
