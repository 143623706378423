import {useDeleteConversationsMutation} from '#/hooks/query/conversations.tsx';
import {useConversations} from '#/hooks/use-conversations';
import {SidebarDropdownMenuContent, SidebarDropdownMenuItem} from '#/library/sidebar/SidebarDropdownMenu';
import {ConversationSummaryResponse} from '#/repositories/assistants-api/requests/fetch-conversations';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {DotsHorizontalIcon, TrashIcon} from '@radix-ui/react-icons';
import {ComponentProps, FunctionComponent, forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export interface ConversationListMoreMenuProps {
  isOpen: boolean;
  conversations: ConversationSummaryResponse[];
  onOpenChange: (isOpen: boolean) => void;
}

const ConversationListMoreMenu = (props: ConversationListMoreMenuProps) => {
  const {t} = useTranslation();

  const {isOpen, conversations, onOpenChange} = props;
  const {activeConversationId} = useConversations();
  const navigate = useNavigate();
  const deleteConversationsMutation = useDeleteConversationsMutation();

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <ConversationListMoreMenuDropdownTrigger />

      <DropdownMenu.Portal>
        <SidebarDropdownMenuContent>
          <SidebarDropdownMenuItem
            className='text-red-600'
            onClick={event => {
              event.preventDefault();
              const conversationIds = conversations.map(conversation => conversation.id);
              const shouldRedirect = activeConversationId != null && conversationIds.includes(activeConversationId);

              deleteConversationsMutation.mutate(conversationIds, {
                onSuccess: () => {
                  if (shouldRedirect) {
                    navigate('/chat');
                    onOpenChange(false);
                  }
                },
              });
            }}
          >
            <TrashIcon width={18} height={18} />
            <span>{t('sidebar.conversations.actions.delete-all')}</span>
          </SidebarDropdownMenuItem>

          <DropdownMenu.Arrow className='fill-surface-02' />
        </SidebarDropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const ConversationListMoreMenuDropdownTrigger: FunctionComponent<ComponentProps<typeof DropdownMenu.Trigger>> =
  forwardRef((props, ref) => (
    <DropdownMenu.Trigger {...props} ref={ref}>
      <DotsHorizontalIcon className='size-4' />
    </DropdownMenu.Trigger>
  ));

export default ConversationListMoreMenu;
