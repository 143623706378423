import {fetchConfig} from '#/repositories/assistants-api/requests/fetch-config';
import {WebappConfig} from '#/repositories/config';

const configResource = (() => {
  let status = 'pending';
  let result: WebappConfig;
  const suspender = fetchConfig().then(
    response => {
      status = 'success';
      result = response.data;
    },
    error => {
      status = 'error';
      result = error;
    },
  );

  return {
    read() {
      if (status === 'pending') throw suspender;
      if (status === 'error') throw result;
      if (status === 'success') return result;
    },
  };
})();

export default configResource;
