import {useUpdateConversationMessageMutation} from '#/hooks/query/conversations.tsx';
import {useToasts} from '#/hooks/use-toasts.tsx';
import {ConversationMessage, ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

const useEditMessage = (
  message: ConversationMessage,
  messageIndex: number,
  conversationId: string | undefined,
  onEditSubmit: (updatedConversation: ConversationResponse) => void,
) => {
  const {t} = useTranslation();
  const {addToast} = useToasts();

  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');

  const handleEditClick = useCallback(() => {
    if (typeof message.content !== 'string') {
      return;
    }
    setIsEditing(true);
    setEditedMessage(message.content);
  }, [message.content]);

  const onEditMessageChange = useCallback((newEditedMessage: string) => {
    setEditedMessage(newEditedMessage);
  }, []);

  const updateConversationMessageMutation = useUpdateConversationMessageMutation();
  const handleEditSubmit = useCallback(async () => {
    if (!conversationId) {
      addToast(t('conversation.toasts.missing-conversation-id'), 'error');
      return;
    }

    const response = await updateConversationMessageMutation.mutateAsync({
      conversationId: conversationId,
      request: {
        message: {
          role: 'user',
          content: editedMessage,
        },
        user_message_index: messageIndex,
      },
    });
    onEditSubmit(response.data);
    setIsEditing(false);
  }, [addToast, conversationId, editedMessage, messageIndex, onEditSubmit, t, updateConversationMessageMutation]);

  const handleEditCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  return {
    isEditing,
    editedMessage,
    handleEditClick,
    onEditMessageChange,
    handleEditSubmit,
    handleEditCancel,
    editIsPending: updateConversationMessageMutation.isPending,
  };
};

export default useEditMessage;
