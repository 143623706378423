import ScoutAPI from '#/repositories/assistants-api/api';
import {ConversationResponse} from '#/repositories/assistants-api/requests/fetch-conversation.ts';
import {AxiosResponse} from 'axios';

export interface ConversationSummaryResponse {
  id: string;
  title: string;
  updated_at: string;
  assistant_id: string | null;
}

export const castToConversationSummaryResponse = (data: ConversationResponse): ConversationSummaryResponse => {
  return {
    id: data.id,
    title: data.title,
    updated_at: data.updated_at,
    assistant_id: data.assistant?.id || null,
  };
};

export const fetchConversations = (): Promise<AxiosResponse<ConversationSummaryResponse[]>> => {
  return ScoutAPI.get<null, AxiosResponse<ConversationSummaryResponse[]>>('/conversations');
};
