import TaskStepIcon from '#/components/tasks/create-edit-task/TaskStepIcon.tsx';
import {TaskPlanExecutionStep} from '#/repositories/assistants-api/requests/fetch-tasks.ts';
import {useMemo} from 'react';

const useStepSelectOptions = (executionPlan?: TaskPlanExecutionStep[]) => {
  return useMemo(() => {
    return !executionPlan?.length
      ? []
      : executionPlan.map((step, index) => ({
          value: index.toString(),
          label: <TaskStepOptionLabel step={step} />,
          text: step.short_description,
        }));
  }, [executionPlan]);
};

const TaskStepOptionLabel = ({step}: {step: TaskPlanExecutionStep}) => {
  return (
    <div className='flex items-center gap-2'>
      <TaskStepIcon taskPlanStep={step} className='size-6' />
      <div className='truncate'>{step.short_description}</div>
    </div>
  );
};

export default useStepSelectOptions;
