import {ToolUsedItemProps, ToolUsedItemWrapper} from '#/components/chat-page/tools-used/ToolUsedItem.tsx';
import {castJson} from '#/utils/json-utils.ts';
import {ReaderIcon} from '@radix-ui/react-icons';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

interface GetFileTextContentToolArguments {
  filename: string;
}

const GetFileTextContentToolUsedItem: FunctionComponent<ToolUsedItemProps> = ({toolCall}) => {
  const {t} = useTranslation();

  const toolArguments = useMemo(() => {
    return castJson<GetFileTextContentToolArguments>(toolCall.arguments);
  }, [toolCall.arguments]);

  return (
    <ToolUsedItemWrapper>
      <div className='flex items-center gap-2'>
        <ReaderIcon className='inline-block size-4 shrink-0 text-accent' />
        <span>{t('conversation.tool-used.tools.get-file-text-content', {filename: toolArguments?.filename})}</span>
      </div>
    </ToolUsedItemWrapper>
  );
};

export default GetFileTextContentToolUsedItem;
